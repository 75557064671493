@import '../../styles/palette.scss';
@import '../../styles/mixins.scss';
@import '../../styles/typography.scss';

.ProjectCompanyCard {
  @include flexbox();
    width: 100%;
  .company-card {
    @include flexbox(space-between, center);
    width: 100%;
    border-radius: 1.5rem;
    height: 60px;
    background-color: #F0F0F3;
    border: 1px solid #CCC;
    margin-top: 0.5rem;
    &.can-hover {
      &:hover {
        cursor: pointer;
        background-color: #ecf2f8;
        .note-field {
          .MuiInputBase-root {
            background-color: #ecf2f8;
          }
        }
      }
      &:focus-visible {
        outline: none;
        border: 2px solid $InitioBlue;
      }
    }
    .company-info {
      @include flexbox(space-between, center);
      margin-left: 2.12rem;
      h6 {
        @include title-medium('Manrope');
        margin: 0rem 0rem 0rem 0.75rem;
        text-align: center;
      }
      .MuiAvatar-root {
        @include body-small;
        background-color: transparent;
        border: 2px solid #49454F;
        width: 1.5rem;
        height: 1.5rem;
      }
    }

    .company-status-info {
      @include flexbox(center, center);
      gap: 0.75rem;
      margin-right: 1.5rem;
      .status-badge-wrapper {
        @include flexbox(flex-end, center);
        gap: 1.25rem;
        .status-badge-btn {
          padding: 0rem;
          margin-top: 0.5rem;
          margin-bottom: 0.5rem;
        }
        .status-badge {
          @include label-medium;
          width: fit-content;
          padding: 0.25rem 0.5rem;
          border-radius: .25rem;
          &.blue {
            color: $Primary50;
            background: $Primary95;
            border: 0.063rem solid $Primary80;
          }
          &.black {
            color: $LightOnSurfaceVariant;
            background: $NeutralVariant95;
            border: 0.063rem solid $Neutral80;
          }
          &.green {
            color: $Success50;
            background: $BackgroundGreen;
            border: 0.063rem solid $Success80;
          }
          &.purple {
            color: $Tertiary40;
            background: $Tertiary95;
            border: 0.063rem solid $Tertiary80;
          }
          &.red {
            color: $LightError;
            background: $Error95;
            border: 0.063rem solid $Error80;
          }
          .MuiSvgIcon-root {
            width: 1rem;
            height: 1rem;
            margin-left: 0.25rem;
          }
        }
      }

      .assignee-avatar {
        @include flexbox(center, center, row);
        min-width: 2rem;
        padding: 0rem 0.25rem 0rem;
        height: 2rem;
        border-radius: 0.75rem;
        background-color: #EAE9EC;
        .MuiAvatar-root {
          @include flexbox(center, center, row);
          @include body-small;
          width: 1.60rem;
          height: 1.60rem;
          box-shadow: 0 0 0 1px $White20;
          &:active,
          &:focus {
            box-shadow: 0 0 0 1px $White20, 0 0 0 3px #c9d5e1;
          }
          &:focus-visible {
            box-shadow: 0 0 0 1px $White20, 0 0 0 3px #c9d5e1, 0 0 0 5px #4295F3;
          }
        }
        .working-companies {
          @include label-small;
          background-color: #313033;
          color: #EFF0F3;
          box-shadow: none;
          width: 1rem;
          height: 1rem;
          text-align: center;
          padding: 0.15rem 0.25rem 0.1rem;
          width: auto;
        }
        .initials {
          &.dark {
            color: #49454F;
          }
          &.light {
            color: #FFFFFF;
          }
        }
        &.hidden {
          border: 1px solid #CCC;
        }
      }

      .drag-handle {
        @include flexbox(center, center);
        width: fit-content;
        height: 2.5rem;
        cursor: grab;
        .MuiSvgIcon-root {
          cursor: grab;
        }
      }

      .menu-icon {
          border: 0.125rem solid transparent;
        &.menu-open {
          outline: none;
          border: 0.125rem solid $InitioBlue;
        }
      }
    }
  }
}

.lock-transaction-menu {
  .MuiPaper-root {
    width: 14.811rem;
    border-radius: 0.75rem;
    .MuiButtonBase-root {
      &.active {
        background-color: rgba(66, 149, 243, 0.08);
        box-shadow: 4px 0 0 $InitioBlue inset;
      }
    }
  }
}

#company-status-menu {
  .MuiPaper-root {
    border-radius: 0.75rem;
    &::-webkit-scrollbar {
      width: 16px;
    }
    &::-webkit-scrollbar-track {
      background: transparent;
    }
    &::-webkit-scrollbar-thumb {
      border: 5px solid transparent;
      border-radius: 100px;
      background-color: $DarkOutline;
      background-clip: content-box;
    }
    &::-webkit-scrollbar-corner {
      background: rgb(0 0 0 / 0%);
    }
    .MuiList-root {
      .MuiButtonBase-root {
        margin-bottom: 0.25rem;
        margin-top: 0.25rem;

        &.active {
          background-color: rgba(66, 149, 243, 0.08);
          box-shadow: 4px 0 0 $InitioBlue inset;
        }
        &.sub-status-active {
          background-color: rgba(66, 149, 243, 0.08);
          box-shadow: 4px 0 0 $InitioBlue inset;
        }
      }
    }
  }
}

#sub-menu {

  // box-shadow: 0px 4px 8px 0px rgba(199, 203, 220, 0.85);
  .MuiTooltip-tooltip {
    @include flexbox(flex-start, flex-start, column);
    background-color: #FAFAFD !important;
    height: auto;
    margin-left: 1.2rem;
    padding:0.25rem 0rem ;
    border-radius: 0.75rem;
    box-shadow: 0px 4px 8px 0px rgba(199, 203, 220, 0.85);

    .MuiTooltip-arrow {
      &::before {
        background-color: #FAFAFD;
      }

      font-size: 2.25rem; // Adjust the size as needed
    }

    .MuiButtonBase-root {
      @include body-medium();
      text-transform: none;
      @include flexbox(flex-start, flex-start);
      width: 100%;

      &:focus-visible {
        background: rgb(0 72 132 / 12%);
      }

      &:active {
        background: rgb(0 28 57 / 10%);

        box-shadow: inset 0 4px 8px rgb(18 29 33 / 12%);
      }

      &.active {
        background-color: rgba(66, 149, 243, 0.08);
        box-shadow: 4px 0 0 #4295f3 inset;
      }
    }
  }
}

.arrow {
  position: absolute;
  height: 12px;
  width: 50px;
  z-index: 1000;
  overflow: visible;
  background-color: red;
  transform: rotate(45deg);
}
