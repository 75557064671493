@import "../../styles/mixins.scss";
@import "../../styles/palette.scss";
@import "../../styles/typography.scss";

.CapStructure {
  @include flexbox(flex-start, center, column);
  .inline-tables {
    @include flexbox;
    gap: 24px;
  }
  .CalcTable {
    width: 1340px;
    .MuiTableHead-root {
      .MuiTableRow-root {
        background: rgba(199, 203, 220, 0.5);
        border: none;
        &:first-child {
          .MuiTableCell-root {
            justify-content: center !important;
            align-items: center;
            height: 32px;
            border: none;
            border-top: none;
            border-bottom: 1px solid white;
            &:not(:first-child) {
              border-left: 1px solid white;
            }
          }
        }
      }
    }
    .MuiTableBody-root {
      .MuiTableCell-root {
        &:first-child {
          justify-content: flex-start !important;
          padding-left: 16px;
        }
      }
    }
  }
  .cap-summary {
    @include flexbox(center, center);
    gap: 1.5rem;
    margin-top: 3rem;
    div {
      @include flexbox(center, center, column);
      @include label-large;
      width: 19.375rem;
      height: 5.5rem;
      border-radius: 0.5rem;
      background: #c7cbdc;
      box-shadow: 0px 4px 8px 0px rgba(0, 49, 93, 0.20);
      p {
        @include label-medium;
        text-align: center;
      }
    }
  }
}

@media (max-width: 1700px) {
  .CapStructure {
    .TableElement {
      width: 95%;
      .MuiPaper-root {
        overflow-x: scroll;
        &::-webkit-scrollbar {
          width: 16px;
        }
        &::-webkit-scrollbar-track {
          background: transparent;
        }
        &::-webkit-scrollbar-thumb {
          border: 5px solid transparent;
          border-radius: 100px;
          background-color: $DarkOutline;
          background-clip: content-box;
        }
        &::-webkit-scrollbar-corner {
          background: rgb(0 0 0 / 0%);
        }
      }
      &.third-width,
      &.two-third-width {
        width: 100%;
        .MuiPaper-root {
          overflow: hidden;
        }
      }
    }
    .inline-tables {
      width: 100%;
      padding: 0rem 2.1rem;
      > div:nth-of-type(-n+2) {
        width: 100%;
      }
    }
  }
}