@import "../styles/palette.scss";
@import "../styles/mixins.scss";
@import "../styles/typography.scss";

.SideNavBar {
  .nav-toggle {
    color: $DarkOnSurfaceVariant;
    height: 4rem;
    border-radius: 0;
    background: $MidnightSteel;
    padding: 0rem;

    &:hover {
      background: rgba(0, 28, 57, 0.16);
    }

    &:focus-visible {
      outline: 0.125rem solid $InitioBlue;
    }

    &:active,
    &.isOpen {
      background: #8997b0;
    }

    &.first {
      border: 0.125rem solid $Outline10;
      padding: 0rem;
    }
  }

  .nav-bar {
    position: fixed;
    z-index: 1;
    @include flexbox(flex-start, flex-start, column);
    top: 4rem;
    left: 0;
    background: linear-gradient(0deg,
        rgba(66, 149, 243, 0.05),
        rgba(66, 149, 243, 0.05)), $White20;
    ;
    box-shadow: 0.25rem 1.5rem 1.75rem 0.188rem rgba(0, 49, 93, 0.2);
    height: 100%;
    width: 16.5rem;
    padding: 0.938rem 0.5rem;
    transition: transform 0.4s ease-in-out;

    span {
      @include label-medium;
      display: block;
      color: $LightOnPrimaryContainer;
      padding: 0.75rem;
    }

    hr {
      margin: 0rem;
      margin-bottom: 1.875rem;
      color: $Primary90;
      width: 100%;
    }

    .nav-link {
      @include flexbox();
      color: $Primary60;
      border-radius: 1.875rem;
      text-transform: none;
      margin-bottom: 1rem;
      width: 100%;

      .MuiSvgIcon-root {
        margin-right: 1rem;
      }

      &.active {
        background-color: #d8dfe9;
        color: #001c39;
      }
    }

    .how-can-i-help {
      @include flexbox(center, center, column);
      @include title-small('Manrope');
      color: $DeepPurple;
      font-weight: 700;
      letter-spacing: 0.125rem;
      text-align: center;
      text-transform: uppercase;
      margin-top: 4.5rem;
      width: 15.875rem;
      margin-left: auto;
      margin-right: auto;

      img {
        @include flexbox(center, center);
        width: 10.625rem;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 0.625rem;
      }
    }
  }

  .open {
    transform: translateX(0);
  }

  .closed {
    transform: translateX(-16.5rem);
  }
}

#first-tooltip {
  .MuiTooltip-arrow {
    color: $Primary30;
    font-size: 1.125rem;
    width: 1.125rem;
  }

  .MuiTooltip-tooltip {
    max-width: 17.875rem;
    background-color: $Primary30;
    border-radius: 0.5rem;
    padding: 0rem;
    transform: translateX(0rem) translateY(-0.25rem);

    p {
      @include title-small();
      color: $White0;
      padding: 1rem 1.5rem 0rem;
      margin: 0rem;
      font-style: normal;
      font-weight: 400;
      font-size: 0.875rem;
      line-height: 1.375rem;
      letter-spacing: 0.016rem;
    }

    ul {
      color: $White0;
      padding: 0rem 0rem 1rem 2.813rem;
      margin: 0rem;
      font-style: normal;
      font-weight: 400;
      font-size: 0.875rem;
      line-height: 1.375rem;
      letter-spacing: 0.016rem;
    }

    .MuiButtonBase-root {
      position: absolute;
      min-height: 0.875rem;
      min-width: 0.875rem;
      right: 0.875rem;
      padding: 0rem;
      top: 0.75rem;
      color: $White30;

      .MuiSvgIcon-root {
        min-height: 0.875rem;
        min-width: 0.875rem;
      }
    }
  }
}