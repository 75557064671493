$InitioBlue: #4295f3;
$InitioPurple: #707adb;

$LightSteel: #c7cbdc;
$MidnightSteel: #32374b;
$BlueSteel: #303d5c;
$CoolSteel: #d0d9ee;

$White0: #ffffff;
$White10: #f0f0f0;
$White20: #fafafd;
$White30: #eff0f3;

$Neutral80: #c9c5ca;
$Neutral95: #f4eff4;

$Primary25: #003e66;
$Primary30: #00497d;
$Primary40: #0061a4;
$Primary50: #007bcc;
$Primary60: #78889a;
$Primary70: #63b0ff;
$Primary75: #303d5c;
$Primary80: #9ecaff;
$Primary90: #c9d9e8;
$Primary95: #eaf1ff;

$Secondary30: #404659;
$Secondary40: #575e71;
$Secondary50: #70778b;
$Secondary60: #8a90a5;
$Secondary70: #a4abc0;
$Secondary95: #edf0ff;

$LightTertiary: #707adb;
$Tertiary30: #2f3ba1;
$Tertiary40: #4854ba;
$Tertiary50: #626ed5;
$Tertiary80: #bdc2ff;
$Tertiary95: #f1efff;
$DeepPurple: #2f3677;

$Outline: #ccc;
$LightOutline: #79747e;
$DarkOutline: #999ba5;
$Outline10: #4295f3;
$LightOnSurfaceVariant: #49454f;
$LightSurfaceVariant: #dfe2eb;
$NeutralVariant30: #43474e;
$NeutralVariant95: #edf1fa;
$NeutralContainer: #ddd9de;

$LightOnPrimaryContainer: #001c39;
$DarkOnPrimaryContainer: #d1e4ff;
$DarkOnSecondaryContainer: #e1e2ec;
$LightSurface: #fafafd;
$DarkOnSurfaceVariant: #cac4d0;

$Container2: #fafafd;

$LightErrorContainer: #f9dedc;
$LightError: #b3261e;
$Error80: #ffb4ab;
$Error90: #ffdad6;
$Error95: #ffedea;

$Success50: #008862;
$Success60: #00a577;
$Success80: #00a577;
$Success: #00be8a;

$MidGreen: #88ddc6;

$Background1: #f0f0f3;
$BackgroundGreen: #ecf9f6;

$LightYellow: #ffefd0;
$InitioYellow: #f4c543;
$WarningYellow: #ffc043;
