@mixin typography-defaults($fontFamily: 'Roboto') {
  font-family: $fontFamily, Arial, sans-serif;
  color: #49454f;
  font-weight: 400;
}

// Display
@mixin display-large($fontFamily: 'Roboto') {
  @include typography-defaults($fontFamily);
  font-size: 57px;
  line-height: 64px;
  letter-spacing: -0.25px;
}

@mixin display-medium($fontFamily: 'Roboto') {
  @include typography-defaults($fontFamily);
  font-size: 45px;
  line-height: 52px;
}

@mixin display-small($fontFamily: 'Roboto') {
  @include typography-defaults($fontFamily);
  font-size: 32px;
  line-height: 40px;
}

// Headline
@mixin headline-large($fontFamily: 'Roboto') {
  @include typography-defaults($fontFamily);
  font-size: 28px;
  line-height: 38px;
  font-weight: 600;
}

@mixin headline-medium($fontFamily: 'Roboto') {
  @include typography-defaults($fontFamily);
  font-size: 24px;
  line-height: 32px;
}

@mixin headline-small($fontFamily: 'Roboto') {
  @include typography-defaults($fontFamily);
  font-size: 18px;
  line-height: 24px;
}

@mixin headline-5($fontFamily: 'Roboto') {
  @include typography-defaults($fontFamily);
  font-size: 24px;
  line-height: 32px;
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
}

// Title
@mixin title-large($fontFamily: 'Roboto') {
  @include typography-defaults($fontFamily);
  font-size: 22px;
  line-height: 28px;
  font-weight: 600;
}

@mixin title-medium($fontFamily: 'Roboto') {
  @include typography-defaults($fontFamily);
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.1px;
}

@mixin title-small($fontFamily: 'Roboto') {
  @include typography-defaults($fontFamily);
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.1px;
}

// Label
@mixin label-large($fontFamily: 'Roboto') {
  @include typography-defaults($fontFamily);
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.075px;
}

@mixin label-medium($fontFamily: 'Roboto') {
  @include typography-defaults($fontFamily);
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.5px;
}

@mixin label-small($fontFamily: 'Roboto') {
  @include typography-defaults($fontFamily);
  font-size: 11px;
  line-height: 16px;
  letter-spacing: 0.5px;
}

// Body
@mixin body-large($fontFamily: 'Roboto') {
  @include typography-defaults($fontFamily);
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.5px;
}

@mixin body-medium($fontFamily: 'Roboto') {
  @include typography-defaults($fontFamily);
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 0.25px;
}

@mixin body-small($fontFamily: 'Roboto') {
  @include typography-defaults($fontFamily);
  font-size: 12px;
  line-height: 20px;
  letter-spacing: 0.4px;
}

@mixin body-copy($fontFamily: 'Roboto') {
  @include typography-defaults($fontFamily);
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.4px;
}
