@import '../styles/typography.scss';
@import '../styles/palette.scss';
@import '../styles/mixins.scss';

.SubNav {
  width: 100%;
  height: 3rem;
  @include flexbox(flex-start, center);
  margin-top: 1px;
  gap: 1px;
  .MuiButtonBase-root {
    background-color: $MidnightSteel;
    border-radius: 0;
    text-transform: none;
    @include flexbox(center, center);
    gap: 10px;
    @include body-large;
    height: 48px;
    padding-left: 12px;
    padding-right: 12px;
    color: $DarkOnPrimaryContainer;
    &.active {
      color: $DarkOnSurfaceVariant;
    }
    &.active,
    &.no-pointer-events {
      pointer-events: none;
    }
    &:hover {
      background-color: #434e68;
    }
    &:active {
      background-color: #556685;
    }
    &:focus-visible {
      background-color: #434e68;
      outline: 1px solid $InitioBlue;
    }
  }
  .top-nav-right {
    height: 48px;
    flex-grow: 1;
    background-color: $MidnightSteel;
    @include flexbox(flex-end, center);
    gap: 8px;
    padding-right: 16px;
    .priority-dropdown {
      @include select-dropdown;
      color: #49454f;
      height: 28px;
      background-color: $NeutralVariant95;
      border-radius: 4px;
      * {
        border: none;
      }
      border: 1px solid #c9c5ca;
      &:hover {
        * {
          border: none;
        }
      }
      .MuiSelect-select {
        @include flexbox(flex-start, center);
        @include label-medium;
      }
      .MuiSvgIcon-root {
        color: #49454f;
        font-size: 20px;
      }
      .MuiButtonBase-root {
        @include flexbox;
        gap: 20px;
        .high-icon {
          color: $WarningYellow;
        }
        .low-icon {
          color: #49454f;
        }
      }
      .MuiPaper-root {
        margin-top: 16px;
      }
    }
  }
}
