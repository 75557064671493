@import '../../styles/palette.scss';
@import '../../styles/mixins.scss';
@import '../../styles/typography.scss';

.EnterpriseInfo {
  .page-header {
    @include flexbox(flex-start, center);
    width: 880px;
    margin: 48px auto 0;
    h4 {
      @include display-small('Manrope');
      margin: 0;
    }
    .MuiButtonBase-root {
      margin-left: 20px;
      @include outlined-button;
      height: 40px;
    }
    margin-bottom: 24px;
  }
  .profile-settings-container {
    @include white-container(880px);
    padding-top: 4px;
    margin: 32px auto;
    position: relative;
    .info-dropdown {
      margin: 28px auto;
      .header-row {
        @include flexbox(flex-start, center);
        gap: 10px;
        height: 64px;
        padding: 16px 36px;
        margin: 8px -48px 12px -48px;
        &:hover {
          background: rgba(0, 97, 164, 0.1);
        }
        &:active {
          background: rgba(0, 97, 164, 0.2);
        }
        &:focus-visible {
          outline: 2px solid $InitioBlue;
        }
        h5 {
          @include headline-medium;
          margin: 0 !important;
          color: $NeutralVariant30;
        }
        .edit-in-wizard-btn,
        .chevron-btn {
          .MuiSvgIcon-root {
            color: #49454f;
          }
          @include flexbox(center, center);
          border: none;
          border-radius: 50%;
          height: 32px;
          width: 32px;
          background: none;
          &:hover {
            background-color: rgba(0, 97, 164, 0.1) !important;
          }
          &:focus-visible {
            outline: 2px solid $InitioBlue;
          }
          &:active {
            background-color: rgba(0, 97, 164, 0.4) !important;
          }
        }
        .edit-btn {
          margin-left: auto;
          margin-right: 6px;
          .MuiSvgIcon-root {
            color: $LightOutline;
          }
        }
        .btns-container {
          margin-left: auto;
          margin-right: 6px;
          .save-btn {
            @include blue-contained-button;
            height: 32px;
            .loading-spinner {
              filter: brightness(0) saturate(100%) invert(100%) sepia(8%) saturate(7441%) hue-rotate(207deg) brightness(108%) contrast(110%);
              margin-right: 8px;
              height: 16px;
              width: 16px;
              @include spin-infinitely();
            }
          }
          .cancel-btn {
            @include outlined-button;
            height: 32px;
            margin-left: 12px;
          }
        }
        .upward-circle-chevron {
          transition: transform 0.5s linear;
          -webkit-transform: rotate(180deg);
          -moz-transform: rotate(180deg);
          -ms-transform: rotate(180deg);
          -o-transform: rotate(180deg);
          transform: rotate(180deg);
        }
        .downward-circle-chevron {
          transition: transform 0.5s linear;
          -webkit-transform: rotate(360deg);
          -moz-transform: rotate(360deg);
          -ms-transform: rotate(360deg);
          -o-transform: rotate(360deg);
          transform: rotate(360deg);
        }
      }
      .data-tiles-grid {
        display: grid;
        grid-template-columns: repeat(2, 300px);
        justify-content: space-around;
        row-gap: 24px;
        margin-bottom: 20px;
        .data-tile {
          .data-name {
            @include label-medium;
            color: $NeutralVariant30;
            display: block;
            opacity: 0.8;
            margin-bottom: 4px;
          }
          .data {
            @include body-large;
            color: $NeutralVariant30;
            display: block;
            word-break: break-word;
          }
        }
        .user-divider {
          grid-column: span 2;
        }
        &.users {
          hr {
            margin: auto 0px;
          }
          hr:last-of-type {
            display: none;
          }
        }
      }
    }
    hr {
      margin: auto -48px;
    }
  }
}
