@import '../styles/mixins.scss';

.chevron-wrapper {
  $heightWidth: 32px;
  width: $heightWidth;
  height: $heightWidth;
  border-radius: calc($heightWidth / 2);

  .RotatingChevron {
    margin-top: 4px;

    &.upward-chevron {
      @include all-browsers-transform(-180deg);
    }

    &.downward-chevron {
      @include all-browsers-transform(0deg);
    }
  }
}
