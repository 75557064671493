@import '../../styles/palette.scss';
@import '../../styles/mixins.scss';
@import '../../styles/typography.scss';

.Intake {
  .page-header {
    width: 1200px;
    margin: 48px auto 24px;

    .header-nav {
      @include flexbox(flex-start, center);
      @include title-large();

      p {
        font-weight: 400;
        margin: 0;

        &:first-child {
          color: $Primary40;
        }

        &:nth-child(2) {
          margin-left: 24px;
        }
      }

      .MuiButtonBase-root {
        &:first-of-type {
          margin-left: 48px;
        }

        margin-left: 16px;
        @include blue-contained-button;
        height: 40px;

        &.save-btn,
        &.back-btn,
        &.add-company-btn {
          @include outlined-button;
        }

        &.save-btn {
          .loading-spinner {
            filter: brightness(0) saturate(100%) invert(100%) sepia(8%) saturate(7441%) hue-rotate(207deg) brightness(108%) contrast(110%);
            margin-right: 8px;
            height: 16px;
            width: 16px;
            @include spin-infinitely();
          }
        }
      }

      margin-bottom: 16px;
    }

    .header-text {
      @include body-medium;

      p {
        margin: 0;
      }

      margin-bottom: 24px;
    }
  }

  .company-list {
    @include flexbox(flex-start, center, column);
    gap: 8px;
    margin-bottom: 48px;

    .company-btn {
      width: 1246px;
      border-radius: 28px;
      border: 1px solid $Outline;
      overflow: hidden;
      box-shadow: 0px 4px 8px 0px rgba(0, 49, 93, 0.2);

      &:focus-visible {
        outline: 2px solid $InitioBlue;
      }

      .company-btn-head-content {
        background-color: $Background1;

        &:hover {
          cursor: pointer;
        }

        &:hover,
        &.is-open {
          background-color: #d8e2eb;
        }

        &:active {
          background-color: #c0d3e3;
        }

        object-fit: cover;
        height: 64px;
        padding: 16px 24px;
        @include flexbox(space-between, center);

        .left-btn-side,
        .right-btn-side {
          @include flexbox(center, center);
        }

        span {
          @include body-large('Manrope');
          font-weight: 600;
        }

        .right-btn-side {
          span {
            @include label-large;
          }

          span,
          .rotating-chevron {
            color: $Primary40;
          }

          .rotating-chevron {
            &.upward {
              transition: transform 0.5s linear;
              @include all-browsers-transform(-180deg);
            }

            &.downward {
              transition: transform 0.5s linear;
              @include all-browsers-transform(0deg);
            }
          }
        }

        .number-icon,
        .done-icon {
          padding: 0;
          width: 24px;
          height: 24px;
          margin-right: 16px;
          border-radius: 50%;
          border: 2px solid $LightOnSurfaceVariant;
          @include body-small;
          @include flexbox(center, center);
        }

        .done-icon {
          border: none;
          background-color: $InitioBlue;

          .MuiSvgIcon-root {
            fill: #ffffff;
            padding: 3px;
          }
        }

        .right-chevron-btn {
          margin-left: 32px;
        }

        .right-chevron-btn,
        .right-handle-btn {
          height: 32px;
          width: 32px;
          background: none;
          border: none;
          cursor: grab;
          @include flexbox(center, center);
          border-radius: 50%;

          &:hover {
            background-color: #e6f0f6;
            cursor: pointer;
          }

          &:focus-visible {
            outline: 2px solid $InitioBlue;
          }

          &:active {
            background-color: #99c0db;
          }
        }
      }
    }
  }
}
