@import "../../styles/mixins.scss";
@import "../../styles/palette.scss";
@import "../../styles/typography.scss";

.CalcInputs {
  .top-tool-bar {
    @include flexbox(flex-end, center);
    height: 48px;
    border-bottom: 1px solid $Outline;
    .MuiButtonBase-root {
      @include text-only-button;
      &.submit-button {
        &.no-pointers {
          pointer-events: none;
        }
      }
    }
  }
  .top-tabs-nav {
    height: 52px;
    @include flexbox(flex-start, flex-end);
    margin-bottom: 32px;
    &.client-inputs {
      margin-bottom: 64px;
    }
    .tab-button {
      &:first-child {
        margin-left: 48px;
      }
      @include label-large;
      text-transform: none;
      border-radius: 0;
      font-weight: 400;
      height: 36px;
      padding-left: 16px;
      padding-right: 16px;
      background: none;
      border: none;
      &:hover {
        border-bottom: 4px solid rgba(0, 97, 164, 0.1);
      }
      &.active-tab {
        font-weight: 600;
        border-bottom: 4px solid $InitioBlue;
      }
    }
    border-bottom: 2px solid $DarkOutline;
  }
  .tabs-content {
    margin-left: auto;
    margin-right: auto;
    padding-bottom: 70px;
    .Tab1,
    .Tab2,
    .Tab3 {
      @include flexbox(center);
      flex-direction: column;
    }
  }
  .tables-pg-bottom-nav-buttons {
    position: fixed;
    z-index: 999;
    bottom: 0px;
    width: 100%;
    background: #575e71;
    box-shadow: 0px 1px 0px #dddbda;
    padding-top: 8px;
    padding-bottom: 8px;
    @include flexbox(space-between, center);
    .MuiButtonGroup-root {
      .MuiButtonBase-root {
        width: 32px;
        height: 32px;
        background-color: $Secondary50;
        border: 1px solid $Secondary60;
        &:hover {
          background-color: $Secondary40;
        }
        &:active {
          background-color: $Secondary30;
        }
        &:focus-visible {
          background-color: $Secondary40;
          outline: 2px solid $InitioBlue;
        }
      }
      a.MuiButtonBase-root {
        color: #ffffff;
      }
    }
    .left-buttons {
      @include flexbox(center, center);
      gap: 6px;
      margin-left: 16px;
    }
    .right-buttons {
      @include flexbox;
      gap: 12px;
      margin-right: 16px;
      .MuiButtonBase-root {
        height: 32px;
        @include tonal-contained-button;
        &.report-btn {
          &:hover {
            background: rgba(158, 202, 255, 1);
            box-shadow: 0px 4px 8px rgba(0, 49, 93, 0.5);
          }
        }
        &.blue-btn {
          @include blue-contained-button;
        }
      }
      .loading-spinner {
        margin-right: 8px;
        $spinnerSize: 16px;
        height: $spinnerSize;
        width: $spinnerSize;
        @include spin-infinitely();
      }
    }
    @media only screen and (max-width: 780px) {
      .right-buttons {
        @include flexbox(center, center);
        flex-direction: column;
        gap: 6px;
      }
    }
  }
  .calc-snackbar {
    .MuiPaper-root {
      margin-top: 48px;
      border-radius: 8px;
      background-color: #313033;
      .MuiSnackbarContent-message {
        @include flexbox(center, center);
        gap: 12px;
        span {
          @include body-medium;
          color: #ffffff;
        }
        .MuiButtonBase-root {
          @include text-only-button-dark;
        }
      }
    }
  }
}

.calc-start-dialog,
.calc-success-dialog,
.calc-failure-dialog,
.re-calc-start-dialog,
.finalize-dialog,
.report-sent-dialog,
.send-dev-email,
.dev-email-sent-dialog {
  .MuiPaper-root {
    overflow: hidden;
    border-radius: 24px;
    padding: 32px;
    .close-icon {
      position: absolute;
      right: 37px;
      top: 37px;
      color: #999ba5;
      border-radius: 50%;
      height: 32px;
      width: 32px;
      padding: 2px;
      &:hover {
        background-color: rgba(0, 97, 164, 0.1);
      }
      &:focus-visible {
        outline: 2px solid $InitioBlue;
      }
      &:active {
        background-color: rgba(0, 97, 164, 0.4);
      }
    }
    .box-header {
      @include flexbox(flex-start, center);
      gap: 10px;
      margin-bottom: 21px;
      .MuiSvgIcon-root {
        color: $LightOutline;
        height: 36px;
        width: 36px;
      }
      h4 {
        @include headline-medium;
        margin-bottom: 0;
      }
      &.calc-success {
        .MuiSvgIcon-root {
          color: $Success50;
        }
        h4 {
          color: $Success50;
        }
      }
      &.calc-failure {
        .MuiSvgIcon-root {
          color: #b3261e;
        }
        h4 {
          color: #b3261e;
        }
      }
    }
    p,
    li {
      font-family: "Roboto";
      line-height: 24px;
      font-size: 16px;
    }
    .calc-error-message {
      color: #b3261e;
    }
    .box-buttons {
      @include flexbox(flex-end);
      gap: 10px;
      margin-top: 42px;
      .cancel-btn {
        @include outlined-button;
      }
      .confirm-btn {
        @include blue-contained-button;
      }
    }
  }
}

.loading-inputs-dialog {
  .MuiPaper-root {
    border-radius: 24px;
    padding: 32px;
    .box-header {
      @include flexbox(flex-start, center);
      gap: 10px;
      margin-bottom: 21px;
      h4 {
        font-weight: 400;
        margin: 0;
      }
    }
    .loading-wrapper {
      @include flexbox(center);
      margin: 20px;
      .loading-spinner {
        $spinnerSize: 62px;
        height: $spinnerSize;
        width: $spinnerSize;
        @include spin-infinitely();
      }
    }
  }
}

.calc-is-in-progress-dialog {
  .MuiPaper-root {
    @include flexbox(center, center);
    flex-direction: column;
    gap: 16px;
    text-align: center;
    border-radius: 24px;
    padding: 32px;
    .loading-spinner {
      $spinnerSize: 48px;
      height: $spinnerSize;
      width: $spinnerSize;
      @include spin-infinitely();
    }
    h4 {
      @include headline-medium;
      margin-bottom: 0;
    }
    .dialog-content {
      p {
        &:first-child {
          @include label-large;
        }
        &:not(:first-child) {
          @include body-large;
          margin-bottom: 0;
        }
      }
    }
    .homepage-btn {
      @include text-only-button;
    }
    .dialog-finalize-btn {
      margin-left: auto;
      margin-top: 24px;
      .MuiButtonBase-root {
        margin-right: 12px;
        &:not(:last-child) {
          @include outlined-button;
        }
        &:last-child {
          @include blue-contained-button;
        }
      }
    }
  }
}

.loading-inputs-dialog {
  .MuiPaper-root {
    border-radius: 24px;
    padding: 32px;
    .box-header {
      @include flexbox(flex-start, center);
      gap: 10px;
      margin-bottom: 21px;
      h4 {
        font-weight: 400;
        margin: 0;
      }
    }
    .loading-wrapper {
      @include flexbox(center);
      margin: 20px;
      .loading-spinner {
        $spinnerSize: 62px;
        height: $spinnerSize;
        width: $spinnerSize;
        @include spin-infinitely();
      }
    }
  }
}

.Tab3 {
  max-width: 1520px;
  .inline-tables {
    @include flexbox;
    gap: 24px;
  }
  margin-left: auto;
  margin-right: auto;
}

.Tab2 {
  max-width: 1400px;
  .CalcTable {
    width: 1400px;
  }
  .top-warning {
    @include body-small;
    @include flexbox(flex-start, center);
    gap: 10px;
    border: 1px solid $InitioPurple;
    margin-top: 32px;
    padding-left: 28px;
    text-align: center;
    width: 1400px;
    height: 48px;
  }
}

.Tab3 {
  max-width: 1340px;
  .CalcTable {
    width: 1340px;
    .MuiTableHead-root {
      .MuiTableRow-root {
        background: rgba(199, 203, 220, 0.5);
        border: none;
        &:first-child {
          .MuiTableCell-root {
            justify-content: center !important;
            align-items: center;
            height: 32px;
            border: none;
            border-top: none;
            border-bottom: 1px solid white;
            &:not(:first-child) {
              border-left: 1px solid white;
            }
          }
        }
      }
    }
    .MuiTableBody-root {
      .MuiTableCell-root {
        &:first-child {
          justify-content: flex-start !important;
          padding-left: 16px;
        }
      }
    }
  }
}

.Tab4,
.Tab5 {
  .no-sandbox-dialog,
  .no-report-dialog {
    margin-left: auto;
    margin-right: auto;
    width: 1310px;
    height: 220px;
    @include label-medium;
    @include flexbox(center, center);
    flex-direction: column;
    p {
      &:first-child {
        font-weight: 600;
      }
      margin: 4px;
    }
    background: $Background1;
    border: 1px solid $LightOutline;
    color: $LightOutline;
    margin-top: 32px;
  }
}
