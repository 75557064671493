@import '../styles/palette.scss';
@import '../styles/mixins.scss';
@import '../styles/typography.scss';

.add-companies-block {
  margin: 1.5rem 0rem;

  .company-block-wrapper {
    max-height: 57.875rem;
    overflow-y: auto;
    padding-bottom: 0.75rem;
    &::-webkit-scrollbar {
      margin: 1rem 0rem;
      width: 1rem;
      z-index: 10;
    }
    &::-webkit-scrollbar-track {
      margin: 1rem 0rem;
      background: transparent;
      z-index: 10;
    }
    &::-webkit-scrollbar-thumb {
      border: 0.313rem solid transparent;
      border-radius: 6.25rem;
      background-color: #49454f;
      background-clip: content-box;
      z-index: 10;
    }
    &::-webkit-scrollbar-corner {
      background: rgba(0, 0, 0, 0);
      z-index: 10;
    }
  }

  p {
    @include body-large();
    margin-bottom: 0rem;
  }

  .added {
    margin: 0.75rem 0rem 1.5rem;
  }

  .no-companies {
    margin: 0.75rem 0rem;
  }

  .company-block {
    @include flexbox();
    background-color: #edf1fa;
    border-radius: 0.5rem;
    border: 0.063rem solid $Outline;
    padding: 0.75rem 0rem 0.5rem 1rem;
    margin-top: 0.25rem;

    .company-input {
      display: grid;
      grid-template-columns: repeat(6, 1fr);
      gap: 0rem 1.5rem;
      width: 100%;

      .add-company-block {
        width: 100%;
        border-radius: 0.75rem;
        grid-column: span 2;
        @include label-small;

        &.note {
          @include body-medium;
          grid-column: span 3;

          .MuiFormHelperText-root {
            @include flexbox(flex-end);
            color: #49454f;
          }
        }

        .MuiInputBase-root {
          background-color: $White20;
          .MuiInputAdornment-root {
            @include body-large;
          }
          .MuiInputBase-input {
            @include body-large;
            padding-left: 0rem;
            margin-left: -0.15rem;
          }
        }
      }

      .remove-company {
        @include flexbox(center, center);
        border-left: 1px solid #FFFFFF;

        &::before {
          content: '';
          position: absolute;
          top: 0;
          left: -20px;
        }

        .MuiButtonBase-root {
          @include text-only-button;
          @include flexbox(center, center);
          grid-column: span 1;
          height: 3rem;
        }
      }

      .MuiFormControlLabel-root {
        grid-column: span 3;
        white-space: nowrap;
        height: 1.375rem;

        .MuiButtonBase-root {
          margin: 0;
        }

        .MuiTypography-root {
          @include body-medium;
        }
      }
    }

  }

  .add-company-btn-info-wrapper {
    @include flexbox(flex-start, center);
    gap: 1.5rem;
    margin-top: 1.312rem;

    .add-company-btn {
      @include outlined-button;
      width: 12.875rem;
      height: 2.5rem;
      white-space: nowrap;

      .MuiTypography-root {
        @include body-medium;
      }

      .MuiSvgIcon-root {
        width: 1.125rem;
      }
    }

    p {
      @include body-medium;
      margin: 0rem;
      width: 43.5rem;
    }
  }
}
