@import '../../styles/palette.scss';
@import '../../styles/mixins.scss';
@import '../../styles/typography.scss';

.ClientInputs {
  @include flexbox(flex-start, center, column);
  .edit-btn-wrapper,
  .no-inputs-to-show,
  .tooltip-info-block,
  .input-block,
  .error-info-block,
  hr {
    width: 1312px;
  }
  .no-inputs-to-show {
    padding: 32px 0;
    @include flexbox(center, center);
    flex-direction: column;
    border: 1px solid $LightOutline;
    p {
      &:first-child {
        font-weight: 600;
      }
    }
  }
  .tooltip-info-block {
    @include flexbox(flex-start, center);
    gap: 8px;
    border: 1px solid $InitioBlue;
    padding: 6px 12px;
    .MuiSvgIcon-root {
      color: $InitioBlue;
    }
    .close-icon {
      margin-left: auto;
      .MuiSvgIcon-root {
        color: $LightOnSurfaceVariant;
      }
    }
  }
  .no-inputs-to-show,
  .tooltip-info-block {
    background-color: #cfd9ea;
    margin-bottom: 16px;
    border-radius: 12px;
    p {
      @include body-small;
      margin: 0;
    }
  }
  .error-info-block {
    height: 56px;
    margin-bottom: 1rem;
    @include flexbox(flex-start, center);
    gap: 8px;
    border: 1px solid $LightError;
    border-radius: 12px;
    padding: 6px 12px;
    background-color: #ded7e1;
    p {
      @include body-small;
      margin: 0;
    }
    .MuiSvgIcon-root {
      color: $LightError;
    }
  }
  hr {
    color: $LightOutline;
    margin: 0;
  }
  .edit-btn-wrapper {
    @include flexbox(flex-end);
    .MuiButtonBase-root {
      @include text-only-button;
      height: 32px;
      margin-right: 12px;
      .MuiSvgIcon-root {
        height: 20px;
        width: 20px;
      }
      &.edit-btn {
        @include outlined-button;
      }
      &.submit-btn {
        @include blue-contained-button;
        margin-right: 0;
        &.no-pointers {
          pointer-events: none;
        }
      }
    }
  }
  .input-block {
    margin: 0.25rem 20px;
    border: 1px solid $Outline;
    border-radius: 4px;
    overflow: hidden;
    .block-header {
      background-color: $MidnightSteel;
      height: 48px;
      @include flexbox(flex-start, center);
      h5 {
        @include label-large;
        color: $DarkOnSecondaryContainer;
        margin-left: 24px;
        margin-top: 4px;
        margin-bottom: 0px;
      }
      &.header-four {
        @include flexbox(flex-start, center);
        background-color: $MidnightSteel;
        span {
          @include label-medium;
          color: $DarkOnSecondaryContainer;
          margin-left: 16px;
          margin-top: 2px;
          width: 10.5rem;
          text-align: end;
          p {
            color: $Secondary70;
          }
        }
        &:first-child {
          width: 117%;
          padding-right: 1.5rem;
          padding-left: 10.5rem;
        }
      }
    }
    .holdings-titles {
      background-color: $MidnightSteel;
      height: 28px;
      @include flexbox(flex-start, center);
      border-top: 1px solid $Outline;
      border-bottom: 1px solid $Outline;
      span {
        @include label-medium;
        color: $DarkOnSecondaryContainer;
        margin-top: 2px;
        margin-left: 16px;
        margin-right: 16px;
        width: 220px;
        &:not(:nth-child(-n + 2)) {
          text-align: center;
        }
        &:nth-child(2) {
          width: 260px;
        }
      }
    }
    .holdings-data {
      &:nth-child(odd) {
        background-color: $Background1;
      }
      &:nth-child(even) {
        background-color: $Container2;
      }
      height: 28px;
      @include flexbox(flex-start, center);
      .holding-data {
        @include body-small;
        margin-top: 2px;
        margin-left: 16px;
        margin-right: 16px;
        width: 220px;
        &:not(:nth-child(-n + 2)) {
          text-align: center;
        }
        &:nth-child(2) {
          width: 260px;
        }
      }
      .MuiFormControl-root {
        width: 240px;
        &:nth-child(2) {
          width: 360px;
        }
        &:nth-child(4) {
          width: 230px;
        }
        &:nth-child(5) {
          width: 200px;
        }
        .MuiInputBase-root {
          margin: auto 12px;
          height: 24px;
          border-radius: 4px;
          background-color: #fff;
        }
      }
      .add-holding-btn {
        @include text-only-button;
        height: 24px;
      }
      .MuiIconButton-root {
        margin-left: auto;
      }
    }
    &.block-three {
      margin-bottom: 0rem;
      border-radius: 0.25rem 0.25rem 0rem 0rem;
      border-bottom: none;
    }
    &.block-four {
      margin-top: 0rem;
      border-radius: 0rem 0rem 0.25rem 0.25rem;
      border-top: none;
      overflow-x: auto;
      &::-webkit-scrollbar {
        width: 16px;
      }
      &::-webkit-scrollbar-track {
        background: $LightSurface;
      }
      &::-webkit-scrollbar-thumb {
        border: 5px solid transparent;
        border-radius: 100px;
        background-color: $DarkOutline;
        background-clip: content-box;
      }
      &::-webkit-scrollbar-corner {
        background: rgb(0 0 0 / 0%);
      }
      .input-tiles {
        padding-left: 1rem;
        padding: 0.75rem 1rem 0.25rem 1rem;
        justify-content: none;

        &.fiscal-periods {
          display: flex;
          flex-wrap: wrap;
          gap: 0rem;
          padding: 0rem;
          .indicator-rows {
            display: flex;
            flex-direction: row;
            height: 3rem;
            padding-right: 1.35rem;
            span {
              @include body-small();
              display: flex;
              height: 3rem;
              align-items: center;
              padding-left: 1rem;
              width: 10.5rem;
            }
            .info-tile {
              display: flex;
              justify-content: center;
              align-items: flex-end;
              height: 1.75rem;
              height: 3rem;
              width: 10.5rem;
              .MuiFormControl-root {
                margin-bottom: 0.2 5rem;
                .MuiInputBase-root {
                  border: 1px solid $Outline;
                  fieldset {
                    display: none;
                  }
                }
              }
              p {
                @include body-small();
              }
            }
            &.even {
              background-color: $Background1;
            }
            &.odd {
              background-color: $Container2;
            }
          }
        }
      }
    }
  }
  .input-tiles {
    display: grid;
    grid-template-columns: repeat(4, min-content);
    gap: 1.5rem;
    background-color: $Container2;
    padding: 24px 48px;
    .half-width-tiles {
      @include flexbox;
      gap: 16px;
      .info-tile {
        width: 180px;
      }
    }
    .uploaded-files {
      @include flexbox();
      flex-direction: column;
      gap: 8px;
      span {
        @include body-medium;
      }
      a {
        @include body-medium;
        color: $Primary40;
        &:hover {
          opacity: 0.8;
        }
      }
      .MuiIconButton-root {
        margin-left: 8px;
        padding: 2px;
      }
      .file {
        @include flexbox(flex-start, center);
        .file-name {
          @include body-medium;
          @include flexbox(flex-start, center);
          gap: 8px;
        }
        .file-and-progress {
          @include flexbox;
          flex-direction: column;
          .MuiLinearProgress-root {
            width: 120px;
            margin: 2px 0px;
            height: 4px;
            border-radius: 2px;
            background-color: $Outline;
            .MuiLinearProgress-bar {
              background-color: $InitioBlue;
            }
          }
        }
        &.completed {
          .MuiLinearProgress-root {
            .MuiLinearProgress-bar {
              background-color: $Success;
            }
          }
          .file-name {
            .MuiSvgIcon-root {
              color: $Success;
            }
          }
        }
        .MuiButtonBase-root {
          @include body-medium;
          @include button-to-link;
        }
      }
    }
    .upload-button {
      @include button-to-link;
      height: 24px;
    }
    &.block-two {
      display: grid;
      & > :nth-child(-n + 4) {
        grid-row: 1;
      }
      & > :nth-child(n + 5) {
        grid-row: 2;
        grid-column: span 1;
      }
    }
  }
}

.loading-comps {
  .MuiPaper-root {
    @include flexbox(center, center, column);
    gap: 24px;
    border-radius: 24px;
    padding: 32px;
    font-size: 24px;
    color: $LightOnSurfaceVariant;
    p {
      font-size: 16px;
    }
  }
}
