@import '../../styles/palette.scss';
@import '../../styles/mixins.scss';
@import '../../styles/typography.scss';

.enterprise-home {
  @include flexbox(center, center, column);

  // min-width: 1450px;
  .header-group {
    @include spaced-between-flexbox();
    min-width: 70.625rem;
    margin-top: 2.19rem;

    .search {
      width: 24rem;
      background-color: $White20;
      border-radius: 0.75rem;

      .MuiInputBase-root {
        .MuiOutlinedInput-notchedOutline {
          border-color: transparent;
        }
      }

      input {
        @include body-medium;

        &::placeholder {
          @include body-medium;
          opacity: 1;
        }
      }
    }

    .MuiButtonBase-root {
      @include outlined-button;

      .MuiSvgIcon-root {
        height: 1.125rem;
        margin: 0rem .2rem .1rem -1rem;
      }

      height: 2.5rem;
      width: 11.625rem;
      white-space: nowrap;
    }
  }

  .sub-header {
    @include spaced-between-flexbox();
    min-width: 70.625rem;
    margin: 2.5rem 0rem 0.75rem;

    h4 {
      @include flexbox();
      @include headline-medium;
      margin: 0rem;

      .MuiSvgIcon-root {
        margin: .25rem .5rem 0rem;
      }
    }

    .filter-sort-container {
      @include flexbox(center, flex-end);
      .view-completed {
        color: var(--m-3-ref-primary-primary-40, #0061A4);
        text-align: right;
        font-family: Roboto;
        font-size: 0.75rem;
        font-style: normal;
        font-weight: 600;
        line-height: 1.25rem; /* 166.667% */
        letter-spacing: 0.00469rem;
        text-transform: none;
        padding: 0rem;
        margin-right: 1.5rem;
        &:hover {
          background-color: transparent;
          color: black;
        }

      }

      .sort-by {
        // @include flexbox(flex-start, flex-end);
        @include label-large;
        font-size: 12px;
        white-space: nowrap;

        .MuiInputBase-root {
          * {
            border: none;
            color: $Primary40;
          }

          .MuiSelect-select {
            margin-right: 8px;
            @include label-large;
            color: $Primary40;
            padding: 0px 28px 0px 4px;
            font-size: 12px;
          }

          .MuiSvgIcon-root {
            padding: 2px;
          }
        }
      }
    }
  }

  .progress-columns {
    @include spaced-between-flexbox(center, wrap);
    gap: 0rem 1.5rem;

    .col {
      scrollbar-gutter: stable;
      min-width: 22.5rem;
      height: 67.1rem;
      border-radius: 0.5rem;
      background-image: url('../../../public/backgroundcolumn.png');
      background-repeat: no-repeat;
      background-size: cover;
      overflow: overlay;

      &::-webkit-scrollbar-track-piece {
        width: 20px;
        background: transparent;
        z-index: -10;
      }

      &::-webkit-scrollbar {
        width: 14px;
      }

      &::-webkit-scrollbar-track {
        background: transparent;
        width: 24px;
        margin-top: 80px;
      }

      &::-webkit-scrollbar-thumb {
        border: 5px solid transparent;
        border-radius: 100px;
        background-color: #A4ABC0;
        background-clip: content-box;
        width: 14px;
      }

      &::-webkit-scrollbar-corner {
        background: rgba(0, 0, 0, 0);
      }

      h4 {
        @include headline-medium;
        color: #49454f;
        margin-left: 16px;
        margin-top: 20px;
        margin-right: auto;
        margin-bottom: 28px;
        opacity: 1 !important;
      }

      .filter-by {
        @include flexbox(flex-end);
        width: 100%;
        margin-bottom: 0.51rem;
        opacity: 1 !important;

      }
    }
  }
}