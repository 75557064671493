@import "../styles/typography.scss";
@import "../styles/palette.scss";
@import "../styles/mixins.scss";

.NavBar {
  @include flexbox(flex-start, center);
  background: $MidnightSteel;
  height: 4rem;
  .logo-container {
    @include flexbox(flex-start, flex-start, column);
    margin: 0.375rem 1.125rem;
    .logo {
      width: 5.625rem;
      height: 2rem;
      margin-bottom: 10px;
      margin: 0rem;
      }
    span {
      @include flexbox();
      @include title-small('Roboto');
      margin: 0rem;
      color: #F0F0F0;
      font-weight: 400;
      font-size: 0.563rem;
      line-height: 1rem;
      letter-spacing: 0.047rem;
      top: 42px
    }
    &.investor {
      @include flexbox(flex-start, flex-start, row);
      gap: 0.75rem;
      .logo,
      span {
        @include flexbox(center, center);
        height: 4rem;
        margin: 0rem;
      }
    }
  }

  .right-nav {
    @include flexbox(center, center);
    margin-left: auto;
    gap: 0.75rem;


    .profile-icon {
      margin-right: 1rem;
      color: #f5f5f5;
      border: 0.063rem solid rgba(158, 202, 255, 0.16) !important;
      padding: 0rem;
      border: none;

      span {
        @include body-large;
        color: #f5f5f5;
      }


      .MuiAvatar-root {
        @include title-small('Roboto');
        background-color: rgba(158, 202, 255, 0.16);
        color: $White0;
        font-weight: 400;
        height: 2rem;
        width: 2rem;
        font-size: 0.875rem;
      }

      &:hover {
        background-color: transparent;
        opacity: 0.8;
      }

      &:focus-visible {
        outline: 0.125rem solid $InitioBlue;
      }

      &.first {
        border: 0.063rem solid $Outline10 !important;
        margin-right: 1rem;
        border-radius: 50%;
        padding: 0rem;
      }
    }
  }
}

#nav-bar-dropdown {
  @include select-dropdown();
  .MuiPaper-root {
    border-radius: 0.75rem;
    width: 14.313rem;
    .MuiList-root {
      .MuiButtonBase-root {
        margin-bottom: 0.25rem;
        margin-top: 0.25rem;
        &.active {
          background-color: rgba(66, 149, 243, 0.08);
          box-shadow: 4px 0 0 $InitioBlue inset;
        }
      }
    }
  }
}

#first-tooltip-right {
  .MuiTooltip-arrow {
    color: $Primary30;
    font-size: 1.125rem;
    width: 1.125rem;
  }
  .MuiTooltip-tooltip {
    max-width: 17.875rem;
    background-color: $Primary30;
    border-radius: 0.5rem;
    padding: 0rem;
    transform: translateX(0rem) translateY(-0.313rem);
    p {
      @include title-small();
      color: $White0;
      padding: 1rem 1.5rem 0rem;
      margin: 0rem;
      font-style: normal;
      font-weight: 400;
      font-size: 0.875rem;
      line-height: 1.375rem;
      letter-spacing: 0.016rem;
    }

    ul {
      color: $White0;
      padding: 0rem 1.55rem 1rem 2.813rem;
      margin: 0rem;
      font-style: normal;
      font-weight: 400;
      font-size: 0.875rem;
      line-height: 1.375rem;
      letter-spacing: 0.016rem;
    }

    .MuiButtonBase-root {
      position: absolute;
      min-height: 0.875rem;
      min-width: 0.875rem;
      right: 0.875rem;
      padding: 0rem 0rem 0.625rem;
      top: 0.75rem;
      color: $White30;

      .MuiSvgIcon-root {
        min-height: 0.875rem;
        min-width: 0.875rem;
      }
    }
  }
}