@import '../../styles/palette.scss';
@import '../../styles/mixins.scss';
@import '../../styles/typography.scss';

@mixin table-scrollbar() {
  &::-webkit-scrollbar {
    width: 18px;
  }
  &::-webkit-scrollbar-track {
    background: transparent;
  }
  &::-webkit-scrollbar-thumb {
    border: 5px solid transparent;
    border-radius: 100px;
    background-color: $DarkOutline;
    background-clip: content-box;
  }
  &::-webkit-scrollbar-corner {
    background: rgb(0 0 0 / 0%);
  }
}

.Calculations {
  width: 1344px;
  margin-left: auto;
  margin-right: auto;
  .generate-report-button {
    @include flexbox(flex-end);
    margin-bottom: 24px;
    .MuiButtonBase-root {
      @include blue-contained-button;
      height: 40px;
    }
  }
  .header-info-block {
    border-radius: 8px;
    background-color: $LightSurfaceVariant;
    box-shadow: 0px 4px 8px 0px rgba(199, 203, 220, 0.85);
    padding: 32px 20px;
    margin-bottom: 24px;
    h4 {
      .MuiSvgIcon-root {
        font-size: 48px;
        margin-right: 8px;
      }
      @include display-small('Manrope');
    }
    p,
    ul {
      @include body-small;
      color: $LightOutline;
      margin: 0;
    }
    .header-content {
      @include flexbox();
      gap: 24px;
      .info-block {
        background-color: $BlueSteel;
        padding: 12px;
        padding-right: 64px;
        border-radius: 12px;
        color: $CoolSteel;
        .MuiSvgIcon-root {
          margin-top: 6px;
          margin-bottom: 6px;
        }
        h5 {
          @include label-large;
          color: $CoolSteel;
          margin: 0;
        }
        span {
          font-style: italic;
          font-size: 24px;
        }
        .user-data {
          @include label-large;
          color: #fdfeff;
          font-style: normal;
        }
      }
    }
  }

  .sensitivity-analysis-wrapper {
    padding: 12px 12px;
    background-color: $NeutralVariant95;
    border-radius: 8px;
    margin-top: 24px;
    .sensitivity-analysis {
      padding: 1.5rem 0px;
      margin: 24px;
      border-radius: 12px;
      border: 1px solid $Outline;
      background-color: $Container2;
      .sensitivity-analysis-header {
        @include flexbox(flex-start, center, row);
        @include label-large;
        margin-left: 12px;
        color: $LightTertiary;
        gap: 1rem;
        padding-right: 1rem;
        &.title {
          white-space: nowrap;
        }
        &.copy{
          @include body-small;
        }
      }
      .range-inputs {
        margin-top: 8px;
        @include flexbox;
        .term-input,
        .volatility-input {
          margin: 8px;
          h6 {
            margin-left: 8px;
            margin-bottom: 16px;
          }
          .MuiFormControl-root {
            width: 172px;
            margin: 0 8px;
          }
        }
        .vl {
          height: 100px;
          margin: 0 8px;
        }
      }
      .run-analysis-btn {
        @include flexbox(flex-end);
        margin: 24px;
        margin-top: 12px;
        .MuiButtonBase-root {
          height: 40px;
          @include outlined-button;
        }
      }
      .vol-table-header {
        @include flexbox(flex-start, center);
        background-color: $MidnightSteel;
        @include label-medium;
        color: $DarkOnSecondaryContainer;
        height: 47px;
        padding-left: 16px;
      }
      .volatility-table {
        border: none;
        border-radius: 0;
        .fixed-left {
          border-top-left-radius: 0;
          .table-header {
            overflow: visible;
          }
        }
        .table-sub-headers {
          height: 56px;
        }
        .table-header-top-titles {
          @include flexbox(flex-start, center);
          div {
            height: 30px;
            border-bottom: 1px solid $Outline;
            margin: 0 8px;
            h6 {
              font-size: 12px;
              margin: 0;
              text-align: center;
            }
            &.volatility {
              min-width: 184px;
            }
          }
        }
      }
    }
  }

  .calc-wrapper {
    .calc-header {
      background-color: $LightSurfaceVariant;
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;
      padding: 16px 32px;
      @include flexbox(flex-start, center);
      gap: 8px;
      h4 {
        @include flexbox(flex-start, center);
        gap: 16px;
        @include title-large('Manrope');
        color: $LightOnSurfaceVariant;
        margin: 0;
        margin-right: 16px;
      }
      p {
        @include body-small;
        color: $LightOutline;
      }
      .run-calc-btn,
      .re-run-calc-btn {
        height: 40px;
        margin-left: auto;
        .MuiSvgIcon-root {
          color: inherit;
        }
      }
      .run-calc-btn {
        @include blue-contained-button;
      }
      .re-run-calc-btn {
        @include outlined-button;
      }
    }
    .calc-in-progress,
    .calc-complete {
      padding: 16px 24px;
      @include flexbox(flex-start, center);
      gap: 16px;
      background-color: $BackgroundGreen;
      @include body-medium;
      color: $Success50;
      border-top: 1px solid $Outline;
      border-bottom: 1px solid $Outline;
      .dots-circle-spinner {
        font-size: 22px;
      }
      .check-icon {
        width: 18px;
        height: 18px;
        border-radius: 50%;
        @include flexbox(center, center);
        background-color: $Success60;
        .MuiSvgIcon-root {
          fill: #fff;
          padding: 6px;
        }
      }
      .close-icon {
        margin-left: auto;
        color: #999ba5;
        border-radius: 50%;
        height: 32px;
        width: 32px;
        padding: 2px;
        &:hover {
          background-color: rgba(0, 97, 164, 0.1);
        }
        &:focus-visible {
          outline: 2px solid $InitioBlue;
        }
        &:active {
          background-color: rgba(0, 97, 164, 0.4);
        }
      }
    }
    .weight-notification {
      padding: 16px 24px;
      @include flexbox(flex-start, center);
      gap: 16px;
      background-color: $Tertiary95;
      @include body-medium;
      color: $Tertiary30;
      border-top: 1px solid $Outline;
      border-bottom: 1px solid $Outline;
      .MuiSvgIcon-root {
        color: $LightTertiary;
      }
    }

    .calc-content {
      padding: 24px 40px;
      background-color: $Container2;
      .inputs-wrapper {
        @include flexbox;
      }
      h6 {
        @include label-large;
        margin-bottom: 24px;
        .MuiSvgIcon-root {
          margin-right: 8px;
        }
      }
      .vl {
        height: 140px;
        border-right: 1px solid $Outline;
        margin-left: 24px;
        margin-right: 24px;
      }
      .term-volatility-market-adjust {
        @include flexbox;
        gap: 24px;
        h6 {
          @include label-large;
          margin-bottom: 16px;
        }
        .input-title-and-icon {
          @include flexbox(flex-start, center);
          gap: 8px;
          h6 {
            margin-bottom: 0;
          }
          .round-icon-wrapper {
            @include flexbox(center, center);
            border-radius: 50%;
            height: 0.75rem;
            width: 0.75rem;
            .MuiSvgIcon-root {
              color: $Primary40;
              height: 1rem;
              width: 1rem;
              margin: 0;
              padding: 0;
            }
            &:hover {
              border: 2px solid #dde7ed;
              outline: 2px solid #dde7ed;
            }
          }
          margin-bottom: 16px;
        }
        .MuiFormControl-root {
          width: 176px;
        }
        .radio-input-block {
          width: 284px;
          background-color: $NeutralVariant95;
          padding: 12px;
          border-radius: 8px;
          margin-right: 24px;
          span {
            @include body-small;
          }
          .Mui-checked {
            .MuiSvgIcon-root {
              color: $Primary40;
            }
            .MuiTypography-root {
              @include body-medium;
              font-size: 14px;
            }
          }
        }
      }
      .weights-inputs {
        @include flexbox;
        .vl {
          height: 100px;
          border-right: 1px solid $Outline;
          margin-left: 24px;
          margin-right: 24px;
        }
        .weight-input-block {
          h6 {
            color: $Success50;
            margin-bottom: 16px;
          }
          .MuiFormControl-root {
            width: 150px;
            .MuiInputBase-root {
              @include body-large;
              color: $Success50;
              border-color: $Primary30;
            }
          }
        }
      }

      .footnote-btn {
        @include text-only-button;
        margin: 16px;
        margin-bottom: 0;
      }

      .footnotes {
        margin-top: 16px;
        background-color: $NeutralVariant95;
        display: grid;
        grid-template-columns: 1fr 1fr;
        padding: 16px;
        gap: 16px;
        .footnote {
          h6 {
            margin-right: 0;
            margin-bottom: 8px;
          }
          p {
            @include body-small;
            margin: 0;
          }
          .MuiFormControl-root {
            width: 100%;
            .MuiInputBase-root {
              background-color: #fff;
              height: 84px;
              .MuiInputBase-input {
                margin-bottom: auto;
              }
              textarea::-webkit-scrollbar {
                display: none;
              }
            }
          }
          .footnote-characters-left {
            @include body-small;
            width: fit-content;
            margin-right: 8px;
            margin-left: auto;
          }
        }
      }
    }
    .calc-footer {
      border-top: 2px solid $Outline;
      border-bottom-left-radius: 8px;
      border-bottom-right-radius: 8px;
      background-color: $LightSurfaceVariant;
      height: 32px;
    }
  }

  .calc-engine-outputs {
    background-color: #eaebf0;
    border-radius: 8px;
    padding-top: 16px;
    .calc-engine-outputs-header {
      @include flexbox(flex-start, center);
      margin: 0px 32px 16px;
      p {
        @include body-small;
      }
      h6 {
        margin-right: 16px;
        margin-bottom: 0;
      }
    }
    .no-outputs-to-show {
      @include flexbox(center, center);
      @include label-medium;
      background-color: #e4e5ec;
      color: $LightOutline;
      text-align: center;
      border-radius: 12px;
      height: 104px;
      .dots-circle-spinner {
        color: $Primary40;
        font-size: 23px;
      }
    }
    .equity-values-row {
      @include flexbox(flex-start, center);
      gap: 24px;
      padding: 16px;
      .MuiFormControl-root {
        width: 140px;
        pointer-events: none;
      }
      .value-block {
        height: 88px;
        width: 160px;
        margin: 0 2px;
        @include flexbox(center, center, column);
        border-radius: 8px;
        background-color: #42a179;
        span {
          text-align: center;
          @include body-small;
          font-weight: 600;
          color: $Background1;
          &:first-child {
            @include label-large;
            font-weight: 900;
            color: #fff;
            font-size: 16px;
          }
        }
      }
      p {
        background-color: $White30;
        padding: 8px 12px;
        border-radius: 8px;
        margin: 0;
        @include label-medium;
        color: $LightOutline;
      }
    }
  }

  .volatility-table,
  .backsolve-table,
  .pubCo-table,
  .mAndA-table,
  .concluded-table {
    border: 1px solid $Outline;
    border-radius: 8px;
    overflow: hidden;
    @include flexbox();
    .fixed-left {
      border-top-left-radius: 8px;
      position: absolute;
      box-shadow: 0px 4px 8px 0px rgba(0, 49, 93, 0.2);
      -webkit-clip-path: inset(-5px -5px 0px 0px);
      clip-path: inset(-5px -5px 0px 0px);
      .table-header {
        width: 72px;
      }
      .table-rows {
        box-shadow: 2px -2px 8px 0px rgba(0, 49, 93, 0.2);
        overflow: hidden;
      }
    }
    .right-scrollable {
      margin-left: 72px;
      width: 1118px;
    }
    .table-header {
      background-color: $MidnightSteel;
      overflow-x: hidden;
      .table-header-top-titles {
        @include flexbox(flex-start, center);
        div {
          height: 56px;
          border-bottom: 1px solid $Outline;
          h6 {
            @include label-large;
            color: $DarkOnSecondaryContainer;
            margin-top: 30px;
            margin-bottom: 2px;
            margin-left: 16px;
          }
          p {
            @include label-large;
            color: $Secondary70;
            font-style: normal;
          }
          &.term {
            min-width: 52px;
          }
          &.volatility {
            min-width: 184px;
          }
          &.concluded {
            min-width: 1237px;
            &.method-title {
              height: 3.125rem;
              margin: 1rem 1rem 0rem 0rem;
              padding-left: 1rem;
              h6 {
                margin: 0rem;
              }
            }
          }
          &.backsolve,
          &.pubCo,
          &.mAndA {
            min-width: 470px;
          }
        }
      }
      .table-sub-headers {
        @include flexbox(flex-start, center);
        height: 32px;
        .sub-header {
          @include flexbox(center, center, column);
          gap: 6px;
          h6,
          span {
            @include label-medium;
            text-align: center;
          }
          h6 {
            margin: 0;
            color: $DarkOnSecondaryContainer;
          }
          span {
            color: $Secondary70;
          }
        }
        .sub-header-group {
          @include flexbox;
          h6 {
            margin-left: 12px;
          }
          &.term {
            .sub-header {
              min-width: 72px;
              h6 {
                margin-left: 0;
              }
            }
          }
          &.volatility {
            .sub-header {
              min-width: 192px;
              @include flexbox(flex-start, flex-end);
              border-right: 2px solid $Secondary70;
              height: 56px;
              padding-bottom: 2px;
              span {
                text-align: center;
                width: 64px;
                &:first-child {
                  width: 128px;
                }
                color: $DarkOnSecondaryContainer;
              }
            }
          }
          &.concluded {
            .sub-header {
              &:nth-child(1) {
                h6 {
                  margin-right: auto;
                }
                min-width: 200px;
              }
              &:nth-child(n + 2) {
                min-width: 120px;
                h6 {
                  margin-left: 0;
                }
              }
              &:nth-child(7) {
                min-width: 160px;
                h6 {
                  margin-left: 0;
                }
              }
            }
          }
          &.backsolve,
          &.pubCo,
          &.mAndA {
            min-width: 470px;
            .sub-header {
              &:nth-child(1) {
                align-items: flex-start;
                min-width: 200px;
              }
              &:nth-child(2) {
                min-width: 104px;
              }
              &:nth-child(3) {
                min-width: 160px;
              }
            }
          }
        }
      }
    }
    .table-rows {
      overflow: auto;
      @include table-scrollbar();
      .table-row-data {
        @include flexbox(flex-start, center);
        &:nth-child(odd) {
          .table-cell {
            background-color: $Background1;
          }
        }
        &:nth-child(even) {
          .table-cell {
            background-color: $Container2;
          }
        }
        .row-data-group {
          @include flexbox;
          .table-cell {
            height: 32px;
            @include body-small;
            @include flexbox(center, center);
            &:last-child {
              flex-grow: 1;
            }
          }
          &.term {
            .table-cell {
              &:nth-child(1) {
                min-width: 72px;
              }
            }
          }
          &.volatility {
            width: 1271px;
            .table-cell {
              min-width: 200px;
              &.filler-cell {
                min-width: auto;
              }
              span {
                text-align: center;
                width: 66px;
                &:first-child {
                  width: 132px;
                }
              }
              border-right: 1px solid $Container2;
              &.can-hover {
                &:hover {
                  background: rgba(0, 97, 164, 0.1);
                }
                &:focus-visible {
                  outline: none;
                  box-shadow: inset 0px 0px 0px 1px $InitioBlue;
                }
                &.selected {
                  box-shadow: inset 0px 0px 0px 1px $InitioBlue;
                  font-weight: 600;
                  span {
                    @include flexbox(center, center);
                    height: 32px;
                    &:first-child {
                      border-right: 1px solid $InitioBlue;
                    }
                    &:last-child {
                      border-left: 1px solid $InitioBlue;
                    }
                  }
                }
              }
              &.no-data {
                pointer-events: none;
              }
              .MuiSvgIcon-root {
                margin-left: 8px;
                color: $InitioBlue;
                font-size: 16px;
                visibility: hidden;
              }
              &.selected {
                .MuiSvgIcon-root {
                  visibility: visible;
                }
              }
            }
          }
          &.backsolve,
          &.pubCo,
          &.mAndA {
            .table-cell {
              &:nth-child(1) {
                justify-content: flex-start;
                padding-left: 12px;
                min-width: 200px;
              }
              &:nth-child(2) {
                min-width: 110px;
              }
              &:nth-child(3) {
                min-width: 160px;
              }
            }
          }
          &.concluded {
            width: 1237px;
            .table-cell {
              &:nth-child(1) {
                justify-content: flex-start;
                padding-left: 12px;
                min-width: 200px;
              }
              &:nth-child(n + 2) {
                min-width: 120px;
              }
              &:nth-child(7) {
                min-width: 160px;
              }
            }
          }
        }
      }
    }
  }
  .backsolve-table,
  .pubCo-table,
  .mAndA-table,
  .concluded-table {
    flex-direction: column;
    .table-rows {
      overflow-x: visible;
    }
  }
  .tables-wrapper {
    background-color: $NeutralVariant95;
    padding: 12px;
  }
  .methods-table {
    @include flexbox(center);
    &.three-tables {
      @include flexbox(space-between);
    }
    overflow-x: auto;
    @include table-scrollbar();
    margin-bottom: 24px;
    padding: 24px;
    .backsolve-table,
    .pubCo-table,
    .mAndA-table {
      border-radius: 8px;
      .table-header {
        overflow: visible;
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
      }
      .table-rows {
        overflow-x: visible;
        overflow-y: auto;
        @include table-scrollbar();
      }
    }
    .total-row {
      @include flexbox;
      .table-cell {
        @include flexbox(center, center);
        height: 32px;
        background-color: #54aa87;
        &:nth-child(1) {
          min-width: 310px;
          border-bottom-left-radius: 8px;
        }
        &:nth-child(2) {
          text-align: center;
          min-width: 160px;
          background-color: #42a179;
          @include label-medium;
          color: #fff;
          border-bottom-right-radius: 8px;
        }
      }
    }
    .backsolve-table {
      margin-left: 24px;
    }
    .pubCo-table {
      margin: 0 24px;
    }
    .mAndA-table {
      margin-right: 24px;
    }
  }
  .concluded-table {
    .total-row {
      @include flexbox;
      .table-cell {
        @include label-medium;
        @include flexbox(center, center);
        height: 32px;
        color: #fff;
        background-color: #42a179;
        &:nth-child(1) {
          min-width: 325px;
        }
        &:nth-child(2) {
          min-width: 350px;
        }
        &:nth-child(3) {
          min-width: 135px;
        }
        &:nth-child(4) {
          min-width: 140px;
        }
        &:nth-child(5) {
          min-width: 138px;
        }
        &:nth-child(6) {
          min-width: 150px;
        }
      }
    }
    .final-row {
      @include flexbox;
      .table-cell {
        @include label-medium;
        @include flexbox(center, center);
        height: 32px;
        background-color: $Background1;
        &:nth-child(1) {
          min-width: 500px;
        }
        &:nth-child(2) {
          min-width: 130px;
        }
        &:nth-child(3) {
          min-width: 80px;
        }
        &:nth-child(4) {
          min-width: 528px;
        }
      }
    }
  }
}
