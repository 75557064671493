@import '../../../styles/palette.scss';
@import '../../../styles/mixins.scss';
@import '../../../styles/typography.scss';

.FilterMenus {
  padding: 6px 12px;
  background-color: $Background1;
  margin-bottom: 24px;
  .filter-options {
    @include flexbox(flex-start, center);
    gap: 16px;
    span {
      @include label-medium;
    }
    .MuiButtonBase-root {
      @include label-large;
      font-weight: 500;
      height: 32px;
      text-transform: none;
      color: $Primary40;
      border: 1px solid $Primary50;
      border-radius: 8px;
      @include flexbox(flex-start, center);
      gap: 4px;
      .MuiSvgIcon-root {
        font-size: 16px;
      }
      &:hover,
      &:focus-visible {
        background-color: #e2e8ed;
      }
      &:focus-visible {
        outline: 2px solid $InitioBlue;
      }
      &.Mui-selected,
      &.selected {
        background-color: #d7e0e7;
        border: none;
        &:hover,
        &:focus-visible {
          background-color: #cedae3;
        }
      }
    }
  }
  .helper-text {
    margin: 6px auto;
    @include body-small;
    color: $LightOutline;
    @include flexbox(flex-start, center);
    span,
    .MuiButtonBase-root {
      @include body-small;
    }
    span {
      color: $LightOutline;
    }
    .MuiButtonBase-root {
      @include button-to-link;
      color: $Primary40;
    }
  }
}

.comp-filter-menu {
  .MuiPaper-root {
    width: 326px;
    margin-top: 8px;
    box-shadow: 0px 8px 16px 0px rgba(199, 203, 220, 0.85);
    hr {
      margin: 4px;
    }
    .MuiMenuItem-root {
      @include body-large;
      white-space: unset;
    }
    .MuiCheckbox-root {
      padding: 0;
      margin-right: 12px;
      .unchecked-icon {
        width: 16px;
        height: 16px;
        border: 1px solid #49454f;
        border-radius: 2px;
      }
      .checked-icon {
        width: 16px;
        height: 16px;
        border-radius: 2px;
        background-color: $InitioBlue;
        @include flexbox(center, center);
        .MuiSvgIcon-root {
          color: #fafafd;
          padding: 6px;
        }
      }
    }
  }
}

.years-filter {
  .MuiPaper-root {
    width: 424px;
    top: 825px !important;
    .years-filter-content {
      @include flexbox;
      gap: 16px;
      padding: 16px;
      h6 {
        @include label-large;
        color: $BlueSteel;
        margin-bottom: 8px;
      }
      .vr {
        border-right: 1px solid $LightSurfaceVariant;
        height: 311px;
      }
      .MuiSlider-root {
        height: 314px;
        .MuiSlider-rail {
          width: 16px;
          background-color: $Neutral95;
          border: 1px solid $Outline;
        }
        .MuiSlider-markLabel {
          @include body-large;
          color: $BlueSteel;
          margin-left: 8px;
        }
        .MuiSlider-mark {
          background-color: $Primary70;
          height: 4px;
          width: 4px;
          border-radius: 50%;
        }
        .MuiSlider-track {
          background: linear-gradient(180deg, #5f8df5 -3.73%, #2159be 102.99%);
          border: none;
          width: 16px;
        }
        .MuiSlider-thumb {
          background-color: $Primary25;
          height: 26px;
          width: 26px;
        }
      }
      .MuiFormGroup-root {
        margin-left: 12px;
        .MuiButtonBase-root {
          padding: 0 2px;
        }
        .MuiTypography-root {
          @include body-large;
          color: $BlueSteel;
          margin-left: 8px;
        }
      }
    }
  }
}
