@import '../../styles/palette.scss';
@import '../../styles/mixins.scss';
@import '../../styles/typography.scss';

.CompaniesList {
  .page-header {
    width: 1150px;
    margin: 48px auto;
    @include flexbox(flex-start, center);
    @include title-large();
    p {
      font-weight: 400;
      margin: 0;
      &:first-child {
        color: $Primary40;
      }
      &:nth-child(2) {
        margin-left: 24px;
      }
    }
    .MuiButtonBase-root {
      margin-left: 48px;
      @include blue-contained-button;
      height: 40px;
    }
    margin-bottom: 24px;
  }
  .company-list-container {
    @include white-container(1022px);
    min-height: 528px;
    margin: 24px auto;
    .company-list-header {
      @include flexbox(flex-start, center);
      gap: 18px;
      margin-bottom: 32px;
      div {
        margin-bottom: 8px;
        p {
          &:first-child {
            @include headline-large('Manrope');
            margin-bottom: 12px;
          }
          &:nth-child(2) {
            @include body-medium;
            margin: 0;
          }
        }
      }
    }
    ol {
      li {
        margin-bottom: 4px;
      }
      .company-list-input {
        margin-left: 16px;
        width: 780px;
        * {
          border: none !important;
          box-shadow: none !important;
          padding: 0 !important;
        }
        textarea {
          &::placeholder {
            font-style: italic;
          }
        }
      }
    }
  }
  .bottom-btn-container {
    @include flexbox(flex-end);
    width: 1022px;
    margin: 24px auto;
    .MuiButtonBase-root {
      @include blue-contained-button;
      margin-left: auto;
    }
  }
  .loading-spinner {
    filter: brightness(0) saturate(100%) invert(100%) sepia(8%) saturate(7441%) hue-rotate(207deg) brightness(108%) contrast(110%);
    margin-right: 8px;
    height: 16px;
    width: 16px;
    @include spin-infinitely();
  }
}
