@import '../../../styles/palette.scss';
@import '../../../styles/mixins.scss';
@import '../../../styles/typography.scss';

.CompTable {
  border: 1px solid $Outline;
  border-radius: 4px;
  overflow: hidden;
  margin-bottom: 8px;
  .comp-table {
    @include flexbox();
    .fixed-left {
      position: absolute;
      overflow: hidden;
      border-top-left-radius: 4px;
      box-shadow: 0px 4px 8px 0px rgba(0, 49, 93, 0.2);
      .table-header {
        width: 336px;
      }
      .comp-table-rows {
        padding-bottom: 15px;
        -ms-overflow-style: none;
        scrollbar-width: none;
        &::-webkit-scrollbar {
          display: none;
        }
        &.sub-company {
          @include flexbox(center, center);
          padding-bottom: 0;
          padding-top: 1px;
          background-color: $Container2;
          width: 100%;
          .comp-table-row-data {
            width: 100%;
            .row-data-group {
              width: 100%;
              .table-cell {
                width: 100%;
                border-bottom: 1px solid $MidnightSteel;
              }
            }
          }
        }
      }
    }
    .right-scrollable {
      margin-left: 336px;
      width: 972px;
    }
    .table-header {
      background-color: $MidnightSteel;
      overflow-x: hidden;
      .table-header-top-titles {
        height: 32px;
        @include flexbox(flex-start, center);
        gap: 14px;
        div {
          border-bottom: 1px solid $Outline;
          margin-top: 4px;
          h6 {
            @include label-large;
            color: $DarkOnSecondaryContainer;
            margin-bottom: 2px;
            margin-left: 16px;
          }
          &.comp-list {
            min-width: 320px;
          }
          &.market-cap {
            min-width: 360px;
          }
          &.market-growth {
            min-width: 160px;
          }
        }
      }
      .table-sub-headers {
        @include flexbox(flex-start, center);
        height: 48px;
        .sub-header {
          @include flexbox(center, center);
          .chevron-icon {
            color: $DarkOnSecondaryContainer;
            border-radius: 50%;
            height: 20px;
            width: 20px;
            margin: 0 2px;
            .MuiSvgIcon-root {
              height: 16px;
              width: 16px;
            }
            &:hover {
              background-color: #434f68;
            }
            &:focus-visible {
              outline: 2px solid $InitioBlue;
            }
            &.hidden {
              visibility: hidden;
            }
            &.active {
              visibility: visible;
            }
          }
          .sub-header-title {
            @include flexbox(center, center, column);
            gap: 6px;
            padding-left: 24px;
            h6,
            span {
              @include label-medium;
              text-align: center;
            }
            h6 {
              margin: 0;
              color: $DarkOnSecondaryContainer;
            }
            span {
              color: $Secondary70;
            }
          }
        }
        .sub-header-group {
          @include flexbox(flex-start, center);
          &.comp-list {
            position: relative;
            min-width: 210px;
            h6 {
              margin-left: 32px;
            }
            .MuiCheckbox-root {
              position: absolute;
              top: 20px;
              left: 6px;
              padding: 0;
              .unchecked-icon {
                width: 16px;
                height: 16px;
                border: 1px solid $White30;
                border-radius: 2px;
              }
              .checked-icon {
                width: 16px;
                height: 16px;
                border-radius: 2px;
                background-color: $InitioBlue;
                @include flexbox(center, center);
                .MuiSvgIcon-root {
                  color: #fafafd;
                  padding: 6px;
                }
              }
            }
            .sub-header {
              .chevron-icon {
                margin-bottom: 22px;
              }
            }
          }
          &.basic-info {
            .sub-header {
              &:nth-child(1) {
                min-width: 106px;
              }
              &:nth-child(2) {
                min-width: 136px;
              }
              &:nth-child(3) {
                min-width: 144px;
              }
              &:nth-child(4) {
                min-width: 130px;
              }
              &:nth-child(5) {
                min-width: 130px;
              }
              &:nth-child(6),
              &:nth-child(7) {
                min-width: 114px;
                .sub-header-title {
                  padding-left: 0;
                }
              }
            }
          }
          &.val-volatility,
          &.tr-volatility {
            .sub-header {
              min-width: 110px;
              .chevron-icon {
                margin-top: 20px;
              }
            }
          }
          &.market-cap {
            .sub-header {
              &:nth-child(1) {
                min-width: 172px;
              }
              &:nth-child(2) {
                min-width: 186px;
              }
              .chevron-icon {
                margin-top: 20px;
              }
            }
          }
          &.market-growth {
            .sub-header {
              min-width: 160px;
            }
          }
          &.revenue,
          &.EBITDA,
          &.gross-profit,
          &.revenue-growth,
          &.EBITDA-growth,
          &.gross-profit-growth,
          &.revenue-multiples,
          &.EBITDA-multiples,
          &.EBITDA-margin,
          &.gross-margin {
            .sub-header {
              min-width: 166px;
            }
          }
        }
      }
    }
    .comp-table-rows {
      max-height: 518px;
      overflow: scroll;
      .comp-table-update-overlay {
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
        background-color: rgba(240, 240, 243, 0.75);
        z-index: 25;
      }
      &::-webkit-scrollbar {
        width: 16px;
      }
      &::-webkit-scrollbar-track {
        background: transparent;
      }
      &::-webkit-scrollbar-thumb {
        border: 5px solid transparent;
        border-radius: 100px;
        background-color: $DarkOutline;
        background-clip: content-box;
      }
      &::-webkit-scrollbar-corner {
        background: rgb(0 0 0 / 0%);
      }
      .comp-table-row-data {
        @include flexbox(flex-start, center);
        &.odd {
          .table-cell {
            background-color: $Background1;
          }
        }
        &.even {
          .table-cell {
            background-color: $Container2;
          }
        }
        &.checkbox-hovered {
          .table-cell {
            background-color: #e6f0f6;
          }
        }
        &.red-inactive-row {
          .table-cell {
            background-color: #f9eeed;
          }
          &.checkbox-hovered {
            .table-cell {
              background-color: $LightErrorContainer;
            }
          }
        }
        &.yellow-multiples-disabled-row {
          .table-cell {
            background-color: #fff7e9;
          }
          &.checkbox-hovered {
            .table-cell {
              background-color: $LightYellow;
            }
          }
        }
        .row-data-group {
          @include flexbox;
          .table-cell {
            height: 32px;
            @include body-small;
            @include flexbox(center, center);
          }
          &.comp-list {
            .table-cell {
              &:nth-child(1) {
                min-width: 32px;
              }
              &:nth-child(2) {
                @include flexbox(flex-start, center);
                padding-left: 8px;
                min-width: 272px;
              }
              &:nth-child(3) {
                min-width: 32px;
              }
            }
            .outlier-flag-icon {
              color: $DarkOnSecondaryContainer;
              border-radius: 50%;
              height: 22px;
              width: 22px;
              margin: 0 4px;
              .MuiSvgIcon-root {
                height: 16px;
                width: 16px;
                color: $Primary40;
              }
              &:hover {
                background-color: #cfe1ee;
              }
              &:focus-visible {
                outline: 2px solid $InitioBlue;
              }
              &.hidden {
                visibility: hidden;
              }
              &.is-outlier {
                visibility: visible;
                .MuiSvgIcon-root {
                  color: $WarningYellow;
                }
              }
            }
          }
          &.basic-info {
            .table-cell {
              &:nth-child(1) {
                @include flexbox(flex-start, center);
                padding-left: 8px;
                min-width: 106px;
              }
              &:nth-child(2) {
                @include flexbox(flex-start, center);
                padding-left: 8px;
                min-width: 136px;
                color: $LightOutline;
                font-style: italic;
              }
              &:nth-child(3) {
                min-width: 144px;
              }
              &:nth-child(4) {
                min-width: 130px;
              }
              &:nth-child(5) {
                min-width: 130px;
              }
              &:nth-child(6),
              &:nth-child(7) {
                min-width: 114px;
                .MuiButtonBase-root {
                  @include button-to-link;
                  border-radius: 0;
                  height: 32px;
                  width: 114px;
                  &:hover {
                    background-color: $NeutralVariant95 !important;
                  }
                  &:focus-visible {
                    border: 1px solid $InitioBlue;
                  }
                }
              }
            }
          }
          &.val-volatility,
          &.tr-volatility {
            .table-cell {
              min-width: 110px;
            }
          }
          &.market-cap {
            .table-cell {
              &:nth-child(1) {
                min-width: 170px;
              }
              &:nth-child(2) {
                min-width: 190px;
              }
            }
          }
          &.market-growth {
            .table-cell {
              min-width: 160px;
            }
          }
          &.revenue,
          &.EBITDA,
          &.gross-profit,
          &.revenue-growth,
          &.EBITDA-growth,
          &.gross-profit-growth,
          &.revenue-multiples,
          &.EBITDA-multiples,
          &.EBITDA-margin,
          &.gross-margin {
            .table-cell {
              min-width: 166px;
            }
          }
        }
        .MuiCheckbox-root {
          padding: 0;
          .unchecked-icon {
            width: 16px;
            height: 16px;
            border: 1px solid #49454f;
            border-radius: 2px;
          }
          .checked-icon {
            width: 16px;
            height: 16px;
            border-radius: 2px;
            background-color: $InitioBlue;
            @include flexbox(center, center);
            .MuiSvgIcon-root {
              color: #fafafd;
              padding: 6px;
            }
          }
          .remove-check-icon {
            width: 16px;
            height: 16px;
            border-radius: 2px;
            background-color: $LightError;
            @include flexbox(center, center);
            .MuiSvgIcon-root {
              color: #fafafd;
              padding: 6px;
            }
          }
          &.yellow {
            .remove-check-icon {
              background-color: $InitioYellow;
            }
          }
        }
      }
      .description-space {
        &.odd {
          background-color: $Background1;
        }
        &.even {
          background-color: $Container2;
        }
      }
      .short-comp-description,
      .long-comp-description {
        p {
          padding: 4px 16px;
          width: 791px;
          @include body-small;
          &.focused {
            background-color: $NeutralVariant95;
            border: 1px solid $InitioBlue;
          }
          &.hovered {
            background-color: $NeutralVariant95;
          }
        }
        &.odd {
          background-color: $Background1;
        }
        &.even {
          background-color: $Container2;
        }
      }
      &.subject-company {
        overflow: hidden;
        border-bottom: 1px solid $MidnightSteel;
        .row-data-group {
          &.val-volatility,
          &.tr-volatility {
            .table-cell {
              min-width: 110px;
            }
          }
          &.market-cap {
            .table-cell {
              &:nth-child(1) {
                min-width: 170px;
              }
              &:nth-child(2) {
                min-width: 190px;
              }
            }
          }
          &.market-growth {
            .table-cell {
              min-width: 160px;
            }
          }
          &.revenue,
          &.EBITDA,
          &.gross-profit,
          &.revenue-growth,
          &.EBITDA-growth,
          &.gross-profit-growth,
          &.revenue-multiples,
          &.EBITDA-multiples,
          &.EBITDA-margin,
          &.gross-margin {
            .table-cell {
              min-width: 166px;
            }
          }
        }
      }
    }
  }
  .table-footer {
    background-color: $MidnightSteel;
    @include flexbox(flex-end);
    .MuiButtonBase-root {
      @include flexbox(flex-start, center);
      border-right: 1px solid $Outline;
      border-left: 1px solid $Outline;
      border-radius: 0;
      text-transform: none;
      color: $Primary80;
      margin-right: 20px;
      .MuiSvgIcon-root {
        font-size: 20px;
        margin-right: 2px;
      }
    }
  }
}

.active-comp-confirmation-modal {
  .MuiPaper-root {
    overflow: hidden;
    min-width: 686px;
    border-radius: 24px;
    padding: 56px;
    .dialog-header {
      @include flexbox(flex-start, center);
      gap: 8px;
      @include headline-medium;
      color: $LightError;
      .MuiSvgIcon-root {
        width: 36px;
        height: 36px;
      }
      margin-bottom: 24px;
    }
    p {
      @include body-large;
      margin-bottom: 24px;
      &:first-of-type {
        font-weight: 500;
      }
    }
    .bottom-btns {
      @include flexbox(flex-end, center);
      .MuiButtonBase-root {
        @include blue-contained-button;
        &:first-child {
          @include outlined-button;
          margin-right: 16px;
        }
      }
    }
  }
}

.bottom-arrow-tooltip {
  &.select-deselect {
    inset: auto auto 2px 0px !important;
    .MuiTooltip-tooltip {
      text-align: center;
    }
    &.top-start {
      inset: auto auto 2px -4px !important;
      .MuiTooltip-arrow {
        margin-left: 2px;
      }
    }
  }
}
