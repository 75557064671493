@import '../../styles/palette.scss';
@import '../../styles/mixins.scss';
@import '../../styles/typography.scss';

.IntakeBlock {
  @include flexbox();
  cursor: default !important;
  .left-stepper-container {
    width: 330px;
    height: 650px;
    background-color: $LightSurface;
    padding-top: 48px;
    padding-left: 38px;
    .MuiStepLabel-root {
      padding: 0;
      .MuiStepLabel-iconContainer {
        padding: 0;
        width: 24px;
        height: 24px;
        margin-left: 2px;
        margin-right: 16px;
        border-radius: 50%;
        @include flexbox(center, center);
      }
      &.completed {
        .MuiStepLabel-iconContainer {
          background-color: $InitioBlue;
          .MuiSvgIcon-root {
            fill: #ffffff;
            padding: 3px;
          }
        }
      }
      &.is-next,
      &.has-started {
        .MuiStepLabel-iconContainer {
          @include body-small;
          line-height: 0;
          letter-spacing: 0;
          color: $InitioBlue;
          border: 2px solid $InitioBlue;
        }
      }
      &.not-started {
        .MuiStepLabel-iconContainer {
          @include body-small;
          line-height: 0;
          letter-spacing: 0;
          color: $LightOnSurfaceVariant;
          border: 2px solid $LightOnSurfaceVariant;
        }
      }
      .MuiStepLabel-label {
        @include flexbox(flex-start, center);
        @include label-large;
        font-weight: 400;
      }
      &.is-next {
        .MuiStepLabel-label {
          font-weight: 600;
        }
      }
    }
    .individual-step {
      .MuiIconButton-root {
        margin-left: 2px;
        height: 32px;
        width: 32px;
        background: none;
        border: none;
        @include flexbox(center, center);
        border-radius: 50%;
        &:hover {
          background-color: #e6f0f6;
          cursor: pointer;
        }
        &:focus-visible {
          outline: 2px solid $InitioBlue;
        }
        &:active {
          background-color: #99c0db;
        }
      }
    }
    .MuiStepConnector-root {
      margin: 4px auto 4px 13px;
      .MuiStepConnector-line {
        min-height: 12px;
        width: 2px;
        margin-left: 0;
        background: $LightOnSurfaceVariant;
        border: 1px solid $LightOnSurfaceVariant;
        border-radius: 10px;
      }
    }
    .left-submit-btn {
      @include outlined-button;
      margin-top: 28px;
    }
  }
  .intake-blocks {
    @include flexbox(flex-start, center);
    flex-direction: column;
    height: 650px;
    overflow-y: scroll;
    overflow-x: hidden;
    &::-webkit-scrollbar {
      width: 16px;
    }
    &::-webkit-scrollbar-track {
      background: #e4eefb;
    }
    &::-webkit-scrollbar-thumb {
      border: 5px solid transparent;
      border-radius: 100px;
      background-color: #49454f;
      background-clip: content-box;
    }
    .intake-block {
      @include white-container(876px);
      margin: 12px 12px;
      &.active-step {
        outline: 2px solid $InitioBlue;
      }
      &.no-permission {
        opacity: 70%;
        * {
          pointer-events: none !important;
        }
      }
      h4 {
        @include headline-large('Manrope');
        margin: 0;
        .MuiSvgIcon-root {
          font-size: 3rem;
        }
      }
      p {
        @include body-medium;
        margin-bottom: 24px;
        &.no-access-contact-info{
          @include body-large();
        }
      }
      p {
        &:first-of-type {
          margin-top: 12px;
        }
        &:last-of-type {
          @include body-large;
          margin: 0;
        }
      }
      .MuiTypography-root {
        @include body-medium;
      }
      .form-grid {
        margin-top: 32px;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-column-gap: 24px;
        grid-row-gap: 24px;
        .MuiFormHelperText-root {
          @include body-small;
          height: 18px;
          margin-top: 2px;
          margin-left: 16px;
        }
      }
      .safe-convertible-extra-info {
        background-color: $NeutralVariant95;
        margin-left: -48px;
        margin-right: -48px;
        padding: 24px 48px;
        margin-top: 12px;
        .MuiInputBase-root {
          background-color: $Container2;
        }
      }
      .inputLabelNoShrink {
        margin-top: 2px;
        margin-left: 24px;
      }
      .total-amount {
        margin-top: 24px;
        width: 100%;
      }
      .ebitda-checkbox {
        @include flexbox(flex-start, center);
        margin: 24px auto 38px 8px;
        .MuiCheckbox-root {
          padding: 0;
          margin-right: 8px;
          .unchecked-icon {
            width: 16px;
            height: 16px;
            border: 1px solid #49454f;
            border-radius: 2px;
          }
          .checked-icon {
            width: 16px;
            height: 16px;
            border-radius: 2px;
            background-color: $InitioBlue;
            @include flexbox(center, center);
            .MuiSvgIcon-root {
              color: #fafafd;
              padding: 6px;
            }
          }
        }
        .ebitda-checkbox-text {
          @include body-medium;
        }
      }
      .upload-block {
        background-color: $NeutralVariant95;
        margin-left: -48px;
        margin-right: -48px;
        padding: 24px 48px;
        margin-top: 32px;
        .upload-block-header {
          @include flexbox(flex-start, center);
          gap: 12px;
          h4 {
            margin-bottom: 2px;
          }
          .MuiSvgIcon-root {
            color: $NeutralVariant30;
            height: 54px;
            width: 54px;
          }
        }
        .file-upload-container {
          @include flexbox(space-between);
          margin-top: 32px;
          .drag-and-drop-area {
            position: relative;
            border: 2px dashed #49454f;
            border-radius: 24px;
            @include flexbox(center, center);
            flex-direction: column;
            width: 50%;
            height: 200px;
            background-color: #dae4f1;
            .file-upload-loading-wrapper {
              position: absolute;
              top: 0;
              bottom: 0;
              right: 0;
              left: 0;
              border-radius: 24px;
              z-index: 999;
              background: #fafafd;
              opacity: 0.8;
              @include flexbox(center, center);
              .loading-spinner {
                $spinnerSize: 62px;
                height: $spinnerSize;
                width: $spinnerSize;
                @include spin-infinitely();
              }
            }
            &.file-dragging,
            &:hover {
              cursor: default !important;
              border: 2px dashed #0967a7;
              background-color: #f3f5fa;
            }
            .browse-or-drop {
              @include flexbox(center, center, column);
              span {
                @include body-large;
              }
              .MuiSvgIcon-root {
                color: $LightOnSurfaceVariant;
                height: 40px;
                width: 40px;
              }
              .MuiButtonBase-root {
                @include tonal-contained-button;
              }
            }
            .file-style {
              display: none;
            }
          }
          .uploaded-files-display {
            width: 50%;
            .file {
              padding: 16px 20px 24px;
              background-color: #ecf9f6;
              border-radius: 24px;
              margin-left: 15px;
              margin-bottom: 10px;
              font-size: 14px;
              line-height: 22px;
              letter-spacing: 0.25px;
              @include flexbox(space-between, center);
              flex-wrap: wrap;
              .upload-complete {
                margin-top: 8px;
                display: block;
              }
              .file-name-and-checkmark-row {
                @include flexbox(space-between);
                flex-wrap: wrap;
                .checkmark-with-underline {
                  color: #00be8a;
                  width: 17.2px;
                  height: 22px;
                  margin-right: 8px;
                  display: inline-block;
                }
                span {
                  word-wrap: break-word;
                }
              }
              .clear-icon {
                color: #999ba5;
                border-radius: 50%;
                height: 32px;
                width: 32px;
                padding: 2px;
                &:hover {
                  background-color: rgba(0, 97, 164, 0.1);
                }
                &:focus-visible {
                  outline: 2px solid $InitioBlue;
                }
                &:active {
                  background-color: rgba(0, 97, 164, 0.4);
                }
              }
            }
            .MuiLinearProgress-root {
              width: 260px;
              margin: 4px 0px;
              height: 16px;
              border-radius: 16px;
              outline: 1px solid $InitioBlue;
              background-color: #fff;
              .MuiLinearProgress-bar {
                background-color: $InitioBlue;
              }
            }
          }
        }
      }
      .holdings-block {
        background-color: $NeutralVariant95;
        margin-left: -48px;
        margin-right: -48px;
        padding: 24px 12px 2px;
        margin-top: 32px;
        border-top: 1px solid $Outline;
        border-bottom: 1px solid $Outline;
        .column-names {
          @include flexbox();
          gap: 24px;
          p {
            @include label-medium;
            margin: 0;
            &:nth-child(1) {
              width: 120px;
            }
            &:nth-child(2) {
              width: 120px;
            }
            &:nth-child(3) {
              width: 96px;
            }
            &:nth-child(4) {
              width: 96px;
            }
            &:nth-child(5) {
              width: 118px;
            }
            &:nth-child(6) {
              width: 112px;
            }
          }
        }
        .holdings-inputs {
          @include flexbox(flex-start, center);
          gap: 24px;
          margin-top: 12px;
          border-top: 1px solid $Container2;
          border-bottom: 1px solid $Container2;

          .MuiFormControl-root {
            padding-left: 8px;
            margin-top: 8px;
            margin-bottom: 8px;
            &:nth-child(1) {
              width: 120px;
            }
            &:nth-child(2) {
              width: 120px;
            }
            &:nth-child(3) {
              width: 96px;
            }
            &:nth-child(4) {
              width: 96px;
            }
            &:nth-child(5) {
              width: 118px;
            }
            &:nth-child(6) {
              width: 116px;
            }
          }
          .MuiInputBase-root {
            background-color: $Container2;
            height: 28px;
            border-radius: 4px;
            padding-left: 0rem;
            font-size: 0.8rem;
            .MuiInputBase-input {
              padding-left: 5px;
              padding-right: 5px;
            }
        }
          .clear-icon {
            @include flexbox(center, center);
            padding: 0;
            .MuiSvgIcon-root {
              margin-right: 0;
            }
            color: #999ba5;
            border-radius: 50%;
            height: 32px;
            width: 32px;
            &:hover {
              background-color: rgba(0, 97, 164, 0.1);
            }
            &:focus-visible {
              outline: 2px solid $InitioBlue;
            }
            &:active {
              background-color: rgba(0, 97, 164, 0.4);
            }
          }
        }
        .MuiButtonBase-root {
          margin-top: 2px;
          @include text-only-button;
        }
      }
    }
    .submit-btn {
      margin: 12px 12px 12px auto;
      @include blue-contained-button;
    }
  }
}

.confirm-submit-dialog,
.submit-success-dialog {
  .MuiPaper-root {
    overflow: hidden;
    border-radius: 24px;
    padding: 32px;
    width: 686px;
    .close-icon {
      position: absolute;
      right: 37px;
      top: 37px;
      color: #999ba5;
      border-radius: 50%;
      height: 32px;
      width: 32px;
      padding: 2px;
      &:hover {
        background-color: rgba(0, 97, 164, 0.1);
      }
      &:focus-visible {
        outline: 2px solid $InitioBlue;
      }
      &:active {
        background-color: rgba(0, 97, 164, 0.4);
      }
    }
    .box-header {
      @include flexbox(flex-start, center);
      gap: 10px;
      margin-bottom: 21px;
      .MuiSvgIcon-root {
        color: #79747e;
        height: 36px;
        width: 36px;
      }
      h4 {
        @include headline-medium;
        margin-bottom: 0;
      }
      p {
        font-size: 16px;
      }
      &.submit-success {
        .MuiSvgIcon-root {
          color: $Success50;
        }
        h4 {
          color: $Success50;
        }
      }
    }
    p {
      @include body-large;
    }
    .box-buttons {
      @include flexbox(flex-end);
      gap: 10px;
      margin-top: 42px;
      .cancel-btn {
        @include outlined-button;
      }
      .confirm-btn {
        @include blue-contained-button;
      }
    }
  }
}
