@import '../styles/palette.scss';
@import '../styles/mixins.scss';
@import '../styles/typography.scss';

.start-ASC-820,
.create-ASC820-success-dialog {
  .MuiPaper-root {
    overflow-y: auto;
    min-width: 63.875rem;
    min-height: 31.125rem;
    padding: 3rem 3rem 2.5rem;
    border-radius: 1.5rem;
    &::-webkit-scrollbar {
      margin: 1rem 0rem;
      width: 1rem;
      z-index: 10;
    }
    &::-webkit-scrollbar-track {
      margin: 1rem 0rem;
      background: transparent;
      z-index: 10;
    }
    &::-webkit-scrollbar-thumb {
      border: 0.313rem solid transparent;
      border-radius: 6.25rem;
      background-color: #49454f;
      background-clip: content-box;
      z-index: 10;
    }
    &::-webkit-scrollbar-corner {
      background: rgba(0, 0, 0, 0);
      z-index: 10;
    }
    h4 {
      @include headline-large("Manrope");
      font-size: 1.75rem;
    }
    .dialog-header-wrapper {
      @include flexbox(flex-start, flex-start, row);
      img {
        margin-right: 1.5rem;
      }
      .header-intro-text {
        width: 46.375rem;
        .header-instructions {
          @include body-large();
          margin: 0.75rem 0rem 2rem;
        }
      }
      .close-icon {
        position: absolute;
        right: 3rem;
        top: 2.5rem;
      }
    }
    .input-grid {
      display: grid;
      margin-top: 0.5rem;
      grid-template-columns: repeat(2, 1fr);
      grid-column-gap: 1.5rem;
      grid-row-gap: 0.188rem;
      margin-bottom: 0.125rem;
      .assignments {
        display: grid;
        grid-column: span 2;
        grid-gap: inherit;
        grid-template-columns: repeat(3, 2fr);
        margin: 1.5rem 0rem;
      }
    }
    .additional-clients {
      margin-top: 1.5rem;
      .client-input {
        display: grid;
        grid-template-rows: repeat(2, 1fr);
        grid-template-columns: repeat(2, 1fr);
        column-gap: 1.5rem;
        row-gap: 0.5rem;
        grid-column: span 2;
        &:nth-child(n):not(:nth-child(1)) {
          display: grid;
          grid-template-columns: repeat(7, 1fr);
          grid-row: span 3;
          background-color: $NeutralVariant95;
          margin-bottom: 0.25rem;
          border-radius: 0.5rem;
          padding: 1rem 1rem 0rem;
          .MuiTextField-root {
            .MuiInputBase-root {
              background-color: $White20;
            }
            .MuiFormHelperText-root {
              height: 0.8125rem;
              margin-top: 0rem;
            }
          }
          &>*:not(:nth-child(3)) {
            grid-column: span 3;
          }
          &> :nth-child(3) {
            grid-row: span 2;
            border-left: 1px solid #FFFFFF;
          }
          &> :nth-child(4), :nth-child(5) {
            margin-bottom: 0rem;
          }
        }
      }
      .remove-client {
        @include flexbox(center, center);
        display: flex;
        .MuiButtonBase-root {
          @include text-only-button;
          white-space: nowrap;
          height: 2.55rem;
          padding: 8px 14px
        }
        &.empty {
          display: none;
        }
      }
    }
    .roles-tip {
      color: $Primary40;
      .MuiButtonBase-root {
        @include button-to-link();
        margin-left: 0.5rem;
        font-family: Roboto;
        font-style: italic;
        font-weight: 400;
        letter-spacing: 0.01563rem;
        text-decoration-line: underline;
        color: $Primary40;
      }
      p {
        @include body-medium;
        background-color: $Secondary95;
        color: $LightOnSurfaceVariant;
        padding: 0.75rem;
        margin-top: 0.87rem;
      }
    }
    .add-client-wrapper {
      @include flexbox(flex-start, center, row);
      gap: 1.5rem;
      margin: 1.5rem 0rem;
      .MuiButtonBase-root {
        @include outlined-button;
      }
      p {
        @include body-medium;
      }
    }
    .dialog-spacer {
      margin: 0.188rem 0rem;
      &.company-blocks {
        margin-top: 0rem;
      }
    }
    .ancillary-info {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      gap: 1.5rem;
      height: auto;
      margin-top: 1.5rem;
    }
    .selections-copy {
      @include body-medium;
      color: $LightOutline;
    }
    .dialog-footer {
      @include flexbox(flex-start, flex-end, column);
      margin-top: 1.312rem;
      .send-email-btn {
        @include blue-contained-button();
        min-width: 8.5625rem;
        white-space: nowrap;
        .MuiSvgIcon-root {
          width: 1.125rem;
        }
      }
    }
  }
}

.create-ASC820-success-dialog {
  @include flexbox(center, center, column);
  .close-icon {
    position: absolute;
    right: 3rem;
    top: 2.5rem;
  }
  .box-header {
    h4 {
      @include headline-large();
      @include flexbox(center);
      margin: 0 0 1rem auto;
    }
  }
  .img-wrapper {
    @include flexbox(center);
    .success-checkmark {
      width: 15.9rem;
      height: 13.375rem;
    }
  }
  .success-msg-container {
    margin: 2rem auto;
    @include flexbox();
    gap: 0.625rem;
    padding: 1.5rem 4rem;
    background: $BackgroundGreen;
    border-radius: 0.25rem;
    p {
      @include body-copy('Noto Sans');
      color: $Success50;
      margin: 0;
    }
  }
}
